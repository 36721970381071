let policyLanguages = {
  "en": {
    "policy-1": "Privacy Policy",
    "policy-2": "GoGoJumpVPN respect our user’s privacy and make every effort to protect the privacy of our Users. We want you to understand what information we collect, what we don’t collect, and how we collect, use, and store information. We do not collect logs of your activity, including no logging of browsing history, traffic destination, data content, or DNS queries. Also, we never store connection logs, meaning no logs of your IP address, connection timestamp, or session duration.",
    "policy-3": "Last updated on Jun 6, 2024",
    "policy-4": "Acceptance",
    "policy-5": "We are committed to protecting your privacy. Your understanding and compliance with GoGoJumpVPN's Privacy Policy will help us provide you with an efficient and satisfying service experience. Our Privacy Policy covers any personal information that GoGoJumpVPN obtains from you when you use the services provided on GoGoJumpVPN.",
    "policy-6": "Services provided on GoGoJumpVPN, you consent to the terms of our Privacy Policy, including our use of information that is collected or submitted as described in our Privacy Policy.",
    "policy-7": "Information We Collect",
    "policy-8": "When you register on the GoGoJumpVPN you can choose to fill in the email, but it is not necessary. It will not affect registration if you do not fill in the email. We will store this information, but will not share it with any third parties except as necessary to provide the features of the Service.",
    "policy-9": "For example, We may use your information when analyzing user data with third-party platform tools. We will use your contact details to send you notifications about the Service and to respond to customer support requests. You may access and change your personal information by logging in to the GoGoJumpVPN.",
    "policy-10": "GoGoJumpVPN uses your email address for the following reasons:",
    "policy-11": "To provide links to our Site, including password reset emails;",
    "policy-12": "To communicate with you about your VPN services or respond to your communications;",
    "policy-13": "To send marketing information, You may choose to not receive Marketing emails by following the opt-out procedure described in these emails.",
    "policy-14": "GoGoJumpVPN uses your personal information only for the purposes listed in this Privacy Policy, and we do not sell your personal information to third parties.",
    "policy-15": "International Data Transfers",
    "policy-16": "Our service is spread globally, and as such, your data travels through our global servers, which might not or might be outside of your country of residence. We rely on some third-party service providers to enable us to provide you our services. Whenever we transfer your information, we take steps to protect it.",
    "policy-17": "You acknowledge and understand that your information will be Information transmission as it is necessary to provide our services and live up to our Terms of Service.",
    "policy-18": "Information Sercurity",
    "policy-19": "We urge you to protect your own privacy. We recommend that you do not share your GoGoJumpVPN password with anyone or in any unsolicited phone call or email.",
    "policy-20": "All public and private keys and passwords are secured securely using strong cryptographic algorithms where necessary. Appropriate security measures are taken for the protection of this information against unauthorized access, unauthorized alteration, destruction or disclosure of data. Access to personal information by GoGoJumpVPN employees, contractors and agents is restricted. These officials require that information in order to operate, develop or improve our services but are bound by confidentiality obligations. They may be subjected to disciplinary action, which includes termination and criminal prosecution, upon failing to meet these obligations.",
    "policy-21": "Cookies",
    "policy-22": "GoGoJumpVPN uses a few types of different cookies to improve the user experience on the website, such as:",
    "policy-23": "Google Analytics for statistical assessment and website performance improvement;",
    "policy-24": "Affiliate cookies to identify the customers referred to the Site by our partners, so that we can grant the referrers with commissions;",
    "policy-25": "Cookies for personalizing the content of the Site for users, such as setting the default language.",
    "policy-26": "You can set up warnings for every time the Site places a cookie in your browser, or you can choose to disable all cookies. You can do both through your browser settings. Since each browser has a different procedure for managing cookies, look at your browser’s Help Menu to learn the correct way to do it.",
    "policy-27": "Alternatively, you can disable all cookies by visiting the Network Advertising Initiative Opt Out page or by using the Google Analytics Opt Out Browser add-on. Please note that choosing to disable cookies may negatively affect some of the features that make your Site experience more efficient.",
    "policy-28": "Third-Party Websites",
    "policy-29": "The Site may contain links to external websites that do not fall under GoGoJumpVPN’s domain. GoGoJumpVPN is not responsible for the privacy practices or content of such external websites.",
    "policy-30": "Consent and Age Restrictions",
    "policy-31": "By using the Site, Content, Apps, Software, or Services, you agree to have your information handled as described in our Terms of Service and Privacy Policy.",
    "policy-32": "The Services are intended for adults aged 18 and above. If you believe your child has provided information to us, please let us know immediately.",
    "policy-33": "Jurisdiction and Applicable Law",
    "policy-34": "Keeping your information private is our core mission. In service of this mission, GoGoJumpVPN's registered place of business is in Singapore, where has integrated data protection legislation, GoGoJumpVPN complies with the laws or the requirements of law enforcement agencies of the server’s region.",
    "policy-35": "Additionally, we will notify affected users about any requests for their account information, unless prohibited from doing so by law or court order.",
    "policy-36": "Users in the European Union",
    "policy-37": "GoGoJumpVPN is committed to user privacy globally, and our existing practices reflect that through minimal collection of data and ensuring users have control over their personal information. The General Data Protection Regulation (GDPR) of the European Union (EU) requires us to outline those practices in a specific manner for users in the EU.",
    "policy-38": "In line with the GDPR, we collect and process the data outlined in this Privacy Policy on one of the following bases, depending on the circumstances:",
    "policy-39": "For the purposes of fulfilling our contractual obligations to users, including:",
    "policy-40": "Providing users with the Services and Apps they have requested.",
    "policy-41": "Managing user subscriptions and processing payments.",
    "policy-42": "Providing customer support.",
    "policy-43": "For a legitimate interest associated with the operation of our business, including:",
    "policy-44": "Enhancing the quality, reliability, and effectiveness of our Site, Services, and Apps.",
    "policy-45": "Communicating with customers to provide information and seek feedback related to our Services and Apps.",
    "policy-46": "With the consent of users, which users can withdraw at any time.",
    "policy-47": "You can exercise your rights under the GDPR to access, transfer, correct, delete, or object to the processing of your personal information by contacting us at 7x24@cyberheart.io",
    "policy-48": "Changes to the Privacy Policy",
    "policy-49": "We may change our Privacy Policy from time to time, without prior notice to you, consistent with applicable privacy laws and principles. Your continued use of the Site or Services constitutes your acceptance of our Privacy Policy.",
    "policy-50": "How to Contact US",
    "policy-51": "If you have any questions regarding our Privacy Policy and how we handle your information, please feel free to contact us at the following email address: 7x24@cyberheart.io",
    "policy-52": "User Account Deletion",
    "policy-53": "You can delete your GoGoJumpVPN account at any time. At the same time, we will delete the relevant data. Please note that deleted accounts and data cannot be recovered, but you can re-register an account and continue to use GoGoJumpVPN services.",
    "policy-54": `How to delete your account: Please visit the left sidebar of the APP, select "My Account", select "Delete Account" at the bottom of the page, and follow the instruction.`,
},
  "zh": {
    "policy-1": "隐私政策",
    "policy-2": "狗急加速VPN 尊重并全力保护用户隐私。我们希望您了解我们收集的信息，不收集的信息以及我们如何收集、使用和存储信息。我们不收集您的活动日志，包括不记录浏览历史记录、流量数据、数据内容或 DNS 查询。我们也从不存储连接日志，这意味着您的 IP 地址、连接时间或会话持续时间都不会记录日志。",
    "policy-3": "本政策最新修订日期为二零二四年六月六日",
    "policy-4": "同意",
    "policy-5": "我们非常注重用户隐私，因此制定隐私政策。请您仔细阅读这些隐私政策的内容，以协助本平台为您提供满意的服务。本政策内容涵盖本平台如何经由使用本平台而收集所得的个人资料。",
    "policy-6": "使用本网站服务，即表示您已同意接受本平台的隐私政策，并同意本平台按照以下政策收集个人资料及使用方法。",
    "policy-7": "信息收集",
    "policy-8": "当您注册本平台时，您可以选择填写邮箱，但是邮箱不是必须的，不填写也不会影响账号注册。我们非常尊重用户个人信息，不会为满足第三方营销目的而向其出售或出租您的任何信息。我们可能会在下列情况下将您的信息与第三方共享：",
    "policy-9": "比如说，我们在使用第三方平台工具来分析用户数据时可能会使用您的信息。我们会通过您提供的联系方式向您发送服务通知以及对您的客服请求作出回应。您可以登录本平台修改您的个人资料。",
    "policy-10": "狗急加速VPN 使用您的电子邮件地址的原因如下：",
    "policy-11": "提供指向我们网站的链接，包括密码重置电子邮件；",
    "policy-12": "与您交流有关您的 VPN 服务或响应您的交流；",
    "policy-13": "发送营销信息，您可以按照这些电子邮件中所述的退出程序选择不接收营销电子邮件。",
    "policy-14": "狗急加速VPN 仅将您的个人信息用于本隐私政策中列出的目的，我们不会将您的个人信息出售给第三方。",
    "policy-15": "信息传输",
    "policy-16": "我们的服务遍布全球，因此，您的数据将通过我们的全球服务器传输，这些服务器可能不在您居住的国家。我们依靠某些第三方服务提供商来使我们能够为您提供我们的服务。每当我们传输您的信息时，我们都会采取措施对其进行保护。",
    "policy-17": "您承认并了解，您的信息将按照说明进行信息传输，这是提供我们的服务并遵守我们的服务条款所必需的。",
    "policy-18": "信息安全",
    "policy-19": "您也有责任保护及妥善存放个人资料，避免通过任何途径（包括电话以及电邮）向其他人分享或公开您在本平台的登录密码。",
    "policy-20": "必要时，我们会使用强大的加密算法来安全地保护所有公钥和私钥以及密码。采取了适当的安全措施来保护此信息，防止未经授权的访问，未经授权的更改，破坏或泄露数据。狗急加速VPN 员工，承包商和代理商对个人信息的访问受到限制。这些员工要求提供信息以运营、发展或改善我们的服务，但受保密义务的约束。如果不履行这些义务，他们可能会受到纪律处分，包括解雇和刑事起诉。",
    "policy-21": "Cookies",
    "policy-22": "狗急加速VPN 使用几种不同类型的 cookie 来改善网站上的用户体验，例如：",
    "policy-23": "Google Analytics（分析），用于统计评估和网站性能改善；",
    "policy-24": "邀请码 Cookie，以识别我们的合作伙伴推荐给本网站的客户，以便我们可以向推荐人授予佣金；",
    "policy-25": "Cookies，用于为用户个性化网站内容，例如设置默认语言。",
    "policy-26": "您可以在网站每次在浏览器中放置 cookie 时设置警告，也可以选择禁用所有 cookie。您可以同时通过浏览器设置进行操作。由于每种浏览器都有不同的 cookie 管理程序，请查看浏览器的“帮助”菜单以了解正确的方法。",
    "policy-27": "另外，您也可以访问“网络广告主动退出”页面或使用 Google Analytics（分析）退出浏览器插件来禁用所有 cookie 。请注意，选择禁用 cookie 可能会对某些功能产生负面影响，这些功能会使您的网站体验更加高效。",
    "policy-28": "第三方网站",
    "policy-29": "狗急加速VPN 的网站可能包含指向其他第三方网站的链接。请了解并理解，我们不控制他们如何操作其隐私惯例，我们对此不承担任何责任。",
    "policy-30": "同意和年龄限制",
    "policy-31": "使用网站、内容、应用程序、软件或服务，即表示您同意按照我们的服务条款和隐私政策中所述处理您的信息。",
    "policy-32": "该服务适用于18岁以上的成年人。如果您认为您的孩子已经向我们提供了信息，请立即告知我们。",
    "policy-33": "适用法律",
    "policy-34": "使您的信息保密是我们的核心使命。为了履行这一使命，狗急加速VPN 的注册营业地点是在新加坡，新加坡拥有完整的数据保护法规，狗急加速VPN 遵守服务器所在地的法律或执法机构的要求。",
    "policy-35": "请务必注意，狗急加速VPN 不会收集任何 IP 地址、浏览历史记录、流量数据等。",
    "policy-36": "欧盟用户",
    "policy-37": "狗急加速VPN 致力于在全球范围内保护用户隐私，我们现有的做法通过最小化数据收集并确保用户可以控制其个人信息来体现这一点。欧盟（EU）的通用数据保护法规（GDPR）要求我们以特定方式为欧盟用户概述这些做法。",
    "policy-38": "根据 GDPR 的规定，我们会根据以下情况之一收集和处理本隐私政策中概述的数据：",
    "policy-39": "为了履行我们对用户的合同义务，包括：",
    "policy-40": "向用户提供他们所请求的服务和应用程序。",
    "policy-41": "管理用户订阅并处理付款。",
    "policy-42": "提供客户支持。",
    "policy-43": "为了与我们的业务运营相关的合法利益，包括：",
    "policy-44": "提高我们的网站，服务和应用程序的质量，可靠性和有效性。",
    "policy-45": "与客户沟通以提供信息并寻求与我们的服务和应用程序有关的反馈。",
    "policy-46": "经用户同意，该用户可以随时退出。",
    "policy-47": "您可以根据 GDPR 行使您的权利，以访问、转移、更正、删除或反对处理您的个人信息，方法是通过 7x24@cyberheart.io 与我们联系。",
    "policy-48": "隐私政策的变更",
    "policy-49": "根据适用的隐私法律和原则，我们可能会不时更改隐私政策，恕不另行通知。您继续使用本网站或服务即表示您接受我们的隐私政策。",
    "policy-50": "联系我们",
    "policy-51": "如果您对我们的隐私政策以及我们如何处理您的信息有任何疑问，请随时通过以下电子邮件地址与我们联系：7x24@cyberheart.io",
    "policy-52": "用户账号删除",
    "policy-53": "任何时候，您都可以删除您的狗急加速账号。同时，我们会删除相关数据。请注意，删除的账号和数据均无法恢复，但您可以重新注册账号，继续使用狗急加速服务。",
    "policy-54": "账号删除办法：请在 APP 内访问左侧菜单，选择“我的账号”，在“我的账号”页面底端，选择“删除账号”，并根据提示进行操作。",
  },

  "tw": {
    "policy-1": "隱私政策",
    "policy-2": "狗急加速VPN 尊重並全力保護用戶隱私。我們希望您了解我們收集的信息，不收集的信息以及我們如何收集、使用和存儲信息。我們不收集您的活動日誌，包括不記錄瀏覽歷史記錄、流量數據、數據內容或 DNS 查詢。我們也從不存儲連接日誌，這意味著您的 IP 地址、連接時間或會話持續時間都不會記錄日誌。",
    "policy-3": "本政策最新修訂日期為二零二四年六月六日",
    "policy-4": "同意",
    "policy-5": "我們非常註重用戶隱私，因此制定隱私政策。請您仔細閱讀這些隱私政策的內容，以協助本平臺為您提供滿意的服務。本政策內容涵蓋本平臺如何經由使用本平臺而收集所得的個人資料。",
    "policy-6": "使用本網站服務，即表示您已同意接受本平臺的隱私政策，並同意本平臺按照以下政策收集個人資料及使用方法。",
    "policy-7": "信息收集",
    "policy-8": "當您註冊本平臺時，您可以選擇填寫郵箱，但是郵箱不是必須的，不填寫也不會影響賬號註冊。我們非常尊重用戶個人信息，不會為滿足第三方營銷目的而向其出售或出租您的任何信息。我們可能會在下列情況下將您的信息與第三方共享：",
    "policy-9": "比如說，我們在使用第三方平臺工具來分析用戶數據時可能會使用您的信息。我們會通過您提供的聯系方式向您發送服務通知以及對您的客服請求作出回應。您可以登錄本平臺修改您的個人資料。",
    "policy-10": "狗急加速VPN 使用您的電子郵件地址的原因如下：",
    "policy-11": "提供指向我們網站的鏈接，包括密碼重置電子郵件；",
    "policy-12": "與您交流有關您的 VPN 服務或響應您的交流；",
    "policy-13": "發送營銷信息，您可以按照這些電子郵件中所述的退出程序選擇不接收營銷電子郵件。",
    "policy-14": "狗急加速VPN 僅將您的個人信息用於本隱私政策中列出的目的，我們不會將您的個人信息出售給第三方。",
    "policy-15": "信息傳輸",
    "policy-16": "我們的服務遍布全球，因此，您的數據將通過我們的全球服務器傳輸，這些服務器可能不在您居住的國家。我們依靠某些第三方服務提供商來使我們能夠為您提供我們的服務。每當我們傳輸您的信息時，我們都會采取措施對其進行保護。",
    "policy-17": "您承認並了解，您的信息將按照說明進行信息傳輸，這是提供我們的服務並遵守我們的服務條款所必需的。",
    "policy-18": "信息安全",
    "policy-19": "您也有責任保護及妥善存放個人資料，避免通過任何途徑（包括電話以及電郵）向其他人分享或公開您在本平臺的登錄密碼。",
    "policy-20": "必要時，我們會使用強大的加密算法來安全地保護所有公鑰和私鑰以及密碼。采取了適當的安全措施來保護此信息，防止未經授權的訪問，未經授權的更改，破壞或泄露數據。狗急加速VPN 員工，承包商和代理商對個人信息的訪問受到限制。這些員工要求提供信息以運營、發展或改善我們的服務，但受保密義務的約束。如果不履行這些義務，他們可能會受到紀律處分，包括解雇和刑事起訴。",
    "policy-21": "Cookies",
    "policy-22": "狗急加速VPN 使用幾種不同類型的 cookie 來改善網站上的用戶體驗，例如：",
    "policy-23": "Google Analytics（分析），用於統計評估和網站性能改善；",
    "policy-24": "邀請碼 Cookie，以識別我們的合作夥伴推薦給本網站的客戶，以便我們可以向推薦人授予傭金；",
    "policy-25": "Cookies，用於為用戶個性化網站內容，例如設置默認語言。",
    "policy-26": "您可以在網站每次在瀏覽器中放置 cookie 時設置警告，也可以選擇禁用所有 cookie。您可以同時通過瀏覽器設置進行操作。由於每種瀏覽器都有不同的 cookie 管理程序，請查看瀏覽器的“幫助”菜單以了解正確的方法。",
    "policy-27": "另外，您也可以訪問“網絡廣告主動退出”頁面或使用 Google Analytics（分析）退出瀏覽器插件來禁用所有 cookie 。請註意，選擇禁用 cookie 可能會對某些功能產生負面影響，這些功能會使您的網站體驗更加高效。",
    "policy-28": "第三方網站",
    "policy-29": "狗急加速VPN 的網站可能包含指向其他第三方網站的鏈接。請了解並理解，我們不控制他們如何操作其隱私慣例，我們對此不承擔任何責任。",
    "policy-30": "同意和年齡限制",
    "policy-31": "使用網站、內容、應用程序、軟件或服務，即表示您同意按照我們的服務條款和隱私政策中所述處理您的信息。",
    "policy-32": "該服務適用於18歲以上的成年人。如果您認為您的孩子已經向我們提供了信息，請立即告知我們。",
    "policy-33": "適用法律",
    "policy-34": "使您的信息保密是我們的核心使命。為了履行這一使命，狗急加速VPN 的註冊營業地點是在新加坡，新加坡擁有完整的數據保護法規，狗急加速VPN 遵守服務器所在地的法律或執法機構的要求。",
    "policy-35": "請務必註意，狗急加速VPN 不會收集任何 IP 地址、瀏覽歷史記錄、流量數據等。",
    "policy-36": "歐盟用戶",
    "policy-37": "狗急加速VPN 致力於在全球範圍內保護用戶隱私，我們現有的做法通過最小化數據收集並確保用戶可以控制其個人信息來體現這一點。歐盟（EU）的通用數據保護法規（GDPR）要求我們以特定方式為歐盟用戶概述這些做法。",
    "policy-38": "根據 GDPR 的規定，我們會根據以下情況之一收集和處理本隱私政策中概述的數據：",
    "policy-39": "為了履行我們對用戶的合同義務，包括：",
    "policy-40": "向用戶提供他們所請求的服務和應用程序。",
    "policy-41": "管理用戶訂閱並處理付款。",
    "policy-42": "提供客戶支持。",
    "policy-43": "為了與我們的業務運營相關的合法利益，包括：",
    "policy-44": "提高我們的網站，服務和應用程序的質量，可靠性和有效性。",
    "policy-45": "與客戶溝通以提供信息並尋求與我們的服務和應用程序有關的反饋。",
    "policy-46": "經用戶同意，該用戶可以隨時退出。",
    "policy-47": "您可以根據 GDPR 行使您的權利，以訪問、轉移、更正、刪除或反對處理您的個人信息，方法是通過 7x24@cyberheart.io 與我們聯系。",
    "policy-48": "隱私政策的變更",
    "policy-49": "根據適用的隱私法律和原則，我們可能會不時更改隱私政策，恕不另行通知。您繼續使用本網站或服務即表示您接受我們的隱私政策。",
    "policy-50": "聯系我們",
    "policy-51": "如果您對我們的隱私政策以及我們如何處理您的信息有任何疑問，請隨時通過以下電子郵件地址與我們聯系：7x24@cyberheart.io",
    "policy-52": "用戶帳號刪除",
    "policy-53": "任何時候，您都可以刪除您的狗急加速帳號。 同時，我們會刪除相關數據。 請注意，刪除的帳號和資料均無法恢復，但您可以重新註冊帳號，繼續使用狗狗急加速服務。",
    "policy-54": "帳號刪除辦法：請在 APP 內存取左側選單，選擇“我的帳號”，在“我的帳號”頁面底端，選擇“刪除帳號”，並根據提示進行操作。",
  }
}
export default policyLanguages

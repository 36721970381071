<template>
  <div ref="header" class="header">
    <span>
      <img @click="toHome" class="icon logo-title" src="../assets/images/icon_app.png" alt="gojapp"/>
    </span>
      <span class="title langlist logo-title">
       {{store.languages[store.lang]['icon-text'] || '狗急加速'}}
    </span>
      <span class="wai">
      <span class="sel" @click.stop="popup">{{toBeSelectedLanguages[store.lang]}}</span>
      <span class="down-triangle"></span>
      <ul class="language-selector" id="items" :style="{display: isPopup ? 'inherit' : 'none'}">
        <li class="language-item itema" :checkd="thislang==k" v-for="(v, k) in toBeSelectedLanguages" :key='k' @click="check(k)">{{v}}</li>
      </ul>
    </span>
  </div>
</template>

<script>

  import { onMounted, ref, reactive } from "vue"
  import { useStore } from 'vuex'
  import { useRouter } from 'vue-router'
  import utils from '@/utils/utils'

  export default {
    name: 'Head',
    components: {
    },
    setup (props, context) {
      let useStores = useStore()
      let store = useStores.state
      let isPopup = ref(false)
      let thislang = ref(store.lang)
      let toBeSelectedLanguages = reactive({
            'en': 'English',
            'tw': '繁體中文',
            'zh': '简体中文'
      })
      function popup () {
        isPopup.value = true
      }
      function check (currentLang) {
        if (context.attrs.change) context.attrs.change(currentLang)
        else {
          thislang.value = currentLang
          useStores.commit('change', currentLang)
          isPopup.value = false
          document.title = store.languages[store.lang]['title']
        }
        utils.setCookie('lang', currentLang)
      }

      let header = ref(null)
      onMounted(()=>{
        useStores.commit('setHeight' , ['headHeight', header.value.offsetHeight])
        document.addEventListener('click', () => {
          isPopup.value = false
        })
      })
      const router = useRouter()
      function toHome () {
        if (context.attrs.toHome) context.attrs.toHome()
        else {
          router.push({
            name: 'banner'
          })
        }
      }

      return {
        header,
        store,
        thislang,
        toBeSelectedLanguages,
        isPopup,
        popup,
        check,
        toHome,
      }
    }
  }
</script>

<style scoped>
  .header {
    background: black;
    color: white;
    padding: 0.75rem;
    width: 100%;
    display: flex;
    align-items: center;
    position: fixed;
    z-index: 5;
    top: 0;
    font-size: 0;
  }

  .header span {
    display: inline-block;
    vertical-align: middle;
  }

  .header img.icon {
    height: 3.2rem;
    width: 3.2rem;
    border-radius: 0.5rem;
    cursor: pointer;
  }

  .header .title {
    flex: 1;
    margin-left: 0.75rem;
    font-size: 1.75rem;
    cursor: pointer;
  }

  .header .down-triangle {
    margin: 0.65rem 2rem 0 0.25rem;
    border: 0.6rem solid rgba(255, 255, 255, 1);
    border-left-color: transparent;
    border-bottom-color: transparent;
    border-right-color: transparent;
  }

  .header .language-selector {
    background-color: white;
    color: black;
    border: 2px solid black;
    position: absolute;
    top: 4rem;
    right: 2rem;
    padding: 0 0.75rem;
    display: none;
  }

  .header .language-selector .language-item {
    padding: 0.75rem 0;
  }

  .header .language-selector .language-item:not(:last-child) {
    border-bottom-style: dotted;
    border-bottom-color: #d3d3d3;
  }

  .wai {
    cursor: pointer;
    font-size: 1.2rem;
  }
  .wai ul {
    list-style: none;
  }

  .itema[checkd=true]::after {
    content: '✔︎';
    margin-left: .5rem;
  }
</style>

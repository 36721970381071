let termsLanguages = {
  "en": {
    "terms-1":"Terms of Service",
    "terms-2":"Use of this site signifies your agreement to the Terms of Use.GoGoJumpVPN reserves the right, at any time, to modify, revise or update the Terms of Use at any time by updating this posting. Customer confirms his or her acceptance of the modifications or updates by continuing to use the site.",
    "terms-3":"Before using this VPN or you may become a member of GoGoJumpVPN, you must read and accept all of the terms in, and linked to, this Terms of Use and the Privacy Policy. We strongly recommend that, as you read this Terms of Use, you also access and read the linked information.",
    "terms-4":"Last updated on Jun 6, 2024",
    "terms-5":"Services",
    "terms-6":"GoGoJumpVPN Services are transitory digital network communications services, which enhance internet security and privacy. At your individual request and at your sole discretion we automatically provide unsupervised and non-monitored data conduit – virtual private network (VPN) connection – for your exclusive use, at your own risk and responsibility. You are solely and exclusively responsible for the use of the GoGoJumpVPN Services. GoGoJumpVPN will not be liable in any way or form for actions done by its users including criminal liability and civil liability for harm executed or not executed. GoGoJumpVPN liability is further limited by other provisions of these Terms.",
    "terms-7":"We grant you a permission to use the GoGoJumpVPN Services subject to the restrictions set forth in these Terms. Your use of the GoGoJumpVPN Services is at your own risk. The GoGoJumpVPN Services may be modified, updated, interrupted or suspended at any time without notice or liability. We do not bear any liability for any harm or other adverse consequences to you, caused by this. GoGoJumpVPN, its owners, employees, agents and others that are involved with the GoGoJumpVPN services are not in any way or form liable for any harm of any kind executed or not executed, resulting from or arising through or from the use of any account registered with GoGoJumpVPN services.",
    "terms-8":"GoGoJumpVPN guarantees a 99.9% service uptime. In any event, GoGoJumpVPN shall not be liable for any loss, monetary or non-monetary harm beyond what the client paid to GoGoJumpVPN, and this shall not include attorney fees or court costs irrespective of any laws or statutes that prescribe otherwise.",
    "terms-9":"Intellectual Property Rights",
    "terms-10":"Subject to the terms and conditions of these Terms, we grant you a limited, non-exclusive, personal, non-transferable, non-sublicensable, license to:",
    "terms-11":"download and use a copy of the GoGoJumpVPN software; ",
    "terms-12":"use the GoGoJumpVPN Services, including, without limitation, the products and services made available on or through the GoGoJumpVPN software or our website. No other right or license of any kind is granted to you hereunder with respect to the GoGoJumpVPN Services. The license provided herein is effective until terminated. This license automatically terminates if you fail to comply with these Terms.",
    "terms-13":"The GoGoJumpVPN Services, including, but not limited to, the GoGoJumpVPN software, mobile application and all other items, are owned and copyrighted by GoGoJumpVPN, and are protected worldwide. We retain all right, title and interest in and to the GoGoJumpVPN Services and any portion thereof, including, without limitation, all copyrights, trademarks, service marks, trade secrets and other intellectual property rights. You shall not take any action to jeopardize, limit or interfere in any manner with our ownership of and rights with respect to the GoGoJumpVPN Services. All rights are reserved unless otherwise noted.",
    "terms-14":"Prohibited and Restricted Uses",
    "terms-15":"Your access to and use of the GoGoJumpVPN Services is subject to these Terms and all applicable laws and regulations. We reserve the right, at any time, in our sole discretion, with or without notice, to terminate the accounts of, and block access to the GoGoJumpVPN Services to any users who infringe any applicable laws or these Terms.",
    "terms-16":"While using the GoGoJumpVPN Services you yourself agree not to, and agree not assist, encourage, or enable others to use the GoGoJumpVPN Services:",
    "terms-17":"1)	for unlawful, illicit, illegal, criminal or fraudulent activities, including but not limited to port scanning, sending spam, sending opt-in email, scanning for open relays or open proxies, sending unsolicited e-mail or any version or type of email sent in vast quantities even if the email is routed through third-party servers, any pop-up launching, use of stolen credit cards, credit card fraud, financial fraud, cryptocurrency fraud, cloaking, extortion, blackmail, kidnapping, rape, murder, sale of stolen credit cards, sale of stolen goods, offer or sale of prohibited, military and dual use goods, offer or sale of controlled substances, identity theft, hacking, pharming, phishing, scraping in any form or scale, digital piracy, intellectual property infringements and other similar activities;",
    "terms-18":"2)	to assault, interfere, gain unauthorized access, deny service in any way or form to any other network, computer or node through GoGoJumpVPN Services;",
    "terms-19":"3)	to exploit children in any way, including audio, video, photography, digital content, etc.;",
    "terms-20":"4)	to upload or distribute in any way files that contain viruses, worms, trojans, corrupted files, or any other similar software or programs that may damage the operation of another’s computer;",
    "terms-21":"5)	to interfere with or attempt to interfere with the proper working of the GoGoJumpVPN Services, any transactions being offered in connection with the GoGoJumpVPN Services or any other activities conducted by us, disrupt our website or any networks connected to the GoGoJumpVPN Services, or bypass any measures we may use to prevent or restrict access to the GoGoJumpVPN Services;",
    "terms-22":"6)	to exploit the GoGoJumpVPN Services in any unauthorized way whatsoever, including, but not limited to, by trespass or burdening network capacity;",
    "terms-23":"7)	to use any robot, spider, scraper, or other automated means to access our website or GoGoJumpVPN Services for any purpose without our prior written permission;",
    "terms-24":"8)	to collect or harvest personal information about other users of the GoGoJumpVPN Services;",
    "terms-25":"9)	to collect or harvest personal data of individuals, without legitimate interest or consent, or in violation of any applicable law;",
    "terms-26":"10)	to take any action that imposes or may impose an unreasonable or disproportionately large load on our infrastructure;",
    "terms-27":"11)	to share any data or other content with any unreasonably large number of persons, including without limitation sending blast communications to a large number of recipients or sharing content with persons you do not know or who do not know you;",
    "terms-28":"12)	to represent that you are the representative or agent of any of the GoGoJumpVPN Services, including any of its functionality;",
    "terms-29":"13)	to incorporate the GoGoJumpVPN Services or any portion thereof into any other program or product;",
    "terms-30":"14)	to copy or reproduce, in any form or by any means, any part of the GoGoJumpVPN Services;",
    "terms-31":"15)	to modify, translate, reverse engineer, decompile, disassemble, or create any derivative works based on the GoGoJumpVPN Services, including any of its files or documentation, or any portion thereof, or determine or attempt to determine any source code, algorithms, methods or techniques embodied in the GoGoJumpVPN application or any portion thereof;",
    "terms-32":"16)	to violate general ethic or moral norms, good customs and fair conduct norms;",
    "terms-33":"17)	to violate any third party’s rights, including any breach of confidence, personal data, copyright or any other intellectual property or proprietary right;",
    "terms-34":"18)	to threaten, stalk, harm, or harass others, or promote bigotry or discrimination;",
    "terms-35":"19)	to attempt to manipulate the GoGoJumpVPN name, services or products;",
    "terms-36":"20)	to solicit or harvest personal information from or communicate with minors;",
    "terms-37":"21)	attempt to gain unauthorized access to the GoGoJumpVPN Services, user accounts, computer systems or networks connected to the GoGoJumpVPN Services through hacking, password mining, brute force or any other means;",
    "terms-38":"22)	to use the GoGoJumpVPN Services for any military purpose, including cyberwarfare, weapons development, design, manufacture or production of missiles, nuclear, chemical or biological weapons;",
    "terms-39":"23)	to otherwise infringe or circumvent these Terms.",
    "terms-40":"In addition, for users who make use of the bandwidth to download for a long time, which makes the servers abnormal and affects other users, GoGoJumpVPN will limit their speed in order to protect IP from being abused.",
    "terms-41":"For the security of your account, non-customized accounts are limited to personal use. Please do not share your account with others. The loss caused by sharing to others will be borne by you.",
    "terms-42":"We reserve the right to refuse service, suspend accounts or limit access to the GoGoJumpVPN Services in our sole discretion. Such suspension or access limitation may be implemented by GoGoJumpVPN instantly and without any indication, notice or refund. We may suspend your account for clarification, investigation or request you to explain your actions and provide additional information. If your account has been suspended, you must contact us for further information. We may suspend your user account for a reasonable period of time before we terminate a user account permanently.",
    "terms-43":"We are under no obligation to enforce the Terms against you. We encourage you to let us know about the violation of these Terms by any GoGoJumpVPN users; however, in case of such violations we may take appropriate action at our sole discretion.",
    "terms-44":"Disclaimer of Warranties",
    "terms-45":"Reasonable efforts are taken to improve the accuracy and integrity of the GoGoJumpVPN Services, but we are not responsible for downtime, loss of data, corrupt data, service delay, mistakes, out-of-date information, or other errors. Notwithstanding any other provision of these Terms, we reserve the right to change, suspend, remove, or disable access to the GoGoJumpVPN Services, or any functionality comprising a part of the GoGoJumpVPN Services at any time without notice. In no event will we be liable for making these changes. As a registered user in good standing, you may be provided with limited service by GoGoJumpVPN. We do not warrant, and will not have any liability or responsibility for your use of GoGoJumpVPN Services or other the products or services we provide. We may also impose limits on the use of or access to the GoGoJumpVPN Services, for any reason and without notice or liability. Our website, products and services may be unavailable from time to time due to human, digital, mechanical, telecommunication, software, and other failures. We cannot predict or control when such downtime may occur and cannot control the duration of such downtime.",
    "terms-46":"We shall not be responsible or have any liability for any delay or failure to perform to the extent due to unforeseen circumstances or causes beyond its reasonable control, including, without limitation, failures of your telecommunication or internet service providers, Force Majeure, earthquakes, fires, floods, embargoes, labor disputes and strikes, riots, war, novelty of product manufacture or other unanticipated product development problems, and acts of civil and military authorities.",
    "terms-47":"Choice of Law",
    "terms-48":"This Agreement shall be governed by and construed in accordance with the laws of Singapore, excluding its rules governing conflicts of law.",
    "terms-49":"Changes to the Terms of Service",
    "terms-50":"We may change our Terms of Service from time to time, without prior notice to you, consistent with applicable privacy laws and principles. Your continued use of the Site or Services constitutes your acceptance of our Terms of Service.",
    "terms-51":"How to Contact US",
    "terms-52":"If you have any questions regarding our Terms of Service and how we handle your information, please feel free to contact us at the following email address: 7x24@cyberheart.io",
  },
  "zh": {
    "terms-1":"服务条款",
    "terms-2":"狗急加速VPN 有权在任何时间及不需事先通知的情况下修改、取消、增加、替代任何在此列明的条款，而此条款均对本网站的使用者及注册会员有效力及约束力。因此，您应定期浏览本页。如在本条款作出修订后仍继续使用本网站，即被视为接受这些修订。",
    "terms-3":"当您使用 狗急加速VPN 或注册成为本平台会员的同时，您将会被视作接受本条款之所有规则约束。请您在使用本平台前仔细阅读所有有关条款。",
    "terms-4":"本条款最新修订日期为二零二四年六月六日",
    "terms-5":"服务",
    "terms-6":"狗急加速VPN 服务是暂时性的数字网络通信服务，可增强 Internet 安全性和隐私性。根据您的个人要求和您的全权酌情决定权，我们会自动提供不受监管和不受监控的数据管道-虚拟专用网（VPN）连接-供您专有使用，您对使用 狗急加速VPN 服务负全责。狗急加速VPN 对用户采取的行动不承担任何责任，包括对已执行或未执行的损害承担刑事责任和民事责任。这些条款的其他规定进一步限制了 狗急加速VPN 的责任。",
    "terms-7":"我们授予您使用 狗急加速VPN 服务的许可，但要遵守这些条款中规定的限制。您使 狗急加速VPN 服务的风险自负。狗急加速VPN 服务可以随时修改，更新，中断或暂停，恕不另行通知或承担责任。对于由此造成的对您的任何伤害或其他不利后果，我们不承担任何责任。狗急加速VPN，其所有者，员工，代理商和其他与 狗急加速VPN 服务相关的人员，对于因使用或向使用其注册的任何账户而产生或引起的任何已执行或未执行的任何形式的损害，概不负责。",
    "terms-8":"狗急加速VPN 保证99.9％的服务正常运行时间。在任何情况下，狗急加速VPN 对超出客户向 狗急加速VPN 支付的任何损失，金钱或非金钱损失概不负责，并且不包括律师费或法院费用，与任何法律或其他成文法则无关。",
    "terms-9":"知识产权",
    "terms-10":"根据这些条款和条件，我们授予您有限的、非排他的、个人的、不可转让的、不可再许可的许可，以：",
    "terms-11":"下载和使用 狗急加速VPN 软件的副本；",
    "terms-12":"使用 狗急加速VPN 服务，包括但不限于在 狗急加速VPN 软件或我们的网站上或通过我们的网站提供的产品和服务。根据 狗急加速VPN 服务，本协议未授予您任何其他权利或许可。本文提供的许可在终止之前一直有效。如果您不遵守这些条款，该许可将自动终止。",
    "terms-13":"狗急加速VPN 服务（包括但不限于 狗急加速VPN 软件，移动应用程序和所有其他项目）由 狗急加速VPN 拥有并拥有版权，并且在全球范围内受到保护。我们保留对 狗急加速VPN 服务及其任何部分的权利，所有权和利益，包括但不限于所有版权，商标，服务标记，商业秘密和其他知识产权。您不得采取任何行动来危害，限制或以任何方式干扰我们对 狗急加速VPN 服务的所有权和权利。除非另有说明，否则保留所有权利。",
    "terms-14":"禁止事项",
    "terms-15":"您对 狗急加速VPN 服务的访问和使用受这些条款以及所有适用法律和法规的约束。对于任何违反任何适用法律或本条款的用户，我们保留随时酌情决定随时终止或阻止其访问 狗急加速VPN 服务的权利，无论是否另行通知。",
    "terms-16":"在使用 狗急加速VPN 服务时，您自己同意不协助、鼓励或允许其他人使用 狗急加速VPN 服务：",
    "terms-17":"1)	用于不正当的、禁止的、违法、犯罪或者欺诈活动，包括但不限于端口扫描，发送垃圾邮件，发送选择接收的电子邮件，扫描开放中继或开放代理，发送未经请求的电子邮件或发送的任何版本或类型的电子邮件即使通过第三方服务器路由电子邮件，任何弹出窗口启动，使用被盗信用卡，信用卡欺诈，金融欺诈，加密货币欺诈，伪装，敲诈，勒索，绑架，强奸，谋杀，出售的信用卡被盗，出售被盗商品，提供或出售违禁，军事和双重用途商品，提供或出售受控物质，身份盗窃，黑客入侵，伪造，网络钓鱼，任何形式或规模的刮取，数字盗版，知识产权侵权和其他类似活动；",
    "terms-18":"2)	通过 狗急加速VPN 服务以任何方式或形式攻击，干扰，获得未经授权的访问，拒绝任何其他网络，计算机或节点的服务；",
    "terms-19":"3)	以任何方式剥削儿童，包括音频，视频，摄影，数字内容等；",
    "terms-20":"4)	以任何方式上传或分发包含病毒，蠕虫，特洛伊木马，损坏的文件或任何其他可能损害他人计算机操作的类似软件或程序的文件；",
    "terms-21":"5)	干扰或试图干扰 狗急加速VPN 服务的正常工作，与 狗急加速VPN 服务有关的任何交易或我们进行的任何其他活动，破坏我们的网站或与 狗急加速VPN 服务相连的任何网络或绕过任何措施我们可能会用来阻止或限制对 狗急加速VPN 服务的访问；",
    "terms-22":"6)	以任何未经授权的方式利用 狗急加速VPN 服务，包括但不限于侵入或增加网络容量；",
    "terms-23":"7)	未经我们事先书面许可，出于任何目的使用任何机器人，蜘蛛，刮板或其他自动方式访问我们的网站或 狗急加速VPN 服务；",
    "terms-24":"8)	收集或收集有关 狗急加速VPN 服务其他用户的个人信息；",
    "terms-25":"9)	未经合法利益或同意，或违反任何适用法律而收集或收集个人数据；",
    "terms-26":"10)	采取任何可能会对我们的基础架构施加不合理或不成比例的巨大负载的措施；",
    "terms-27":"11)	与任何不合理数量的人共享任何数据或其他内容，包括但不限于向大量接收者发送爆炸性通信或与您不认识或不认识您的人共享内容；",
    "terms-28":"12)	代表您是任何 狗急加速VPN 服务（包括其任何功能）的代表或代理商；",
    "terms-29":"13)	将 狗急加速VPN 服务或其任何部分合并到任何其他程序或产品中；",
    "terms-30":"14)	以任何形式或通过任何方式复制或复制 狗急加速VPN 服务的任何部分；",
    "terms-31":"15)	根据 狗急加速VPN 服务修改，翻译，反向工程，反编译，反汇编或创建任何衍生作品，包括其任何文件或文档，或其任何部分，或确定或尝试确定任何源代码，算法，方法或 狗急加速VPN 应用程序或其任何部分中包含的技术；",
    "terms-32":"16)	违反一般的道德或道德规范，良好风俗和公平行为规范；",
    "terms-33":"17)	侵犯任何第三方的权利，包括任何违反保密性，个人数据，版权或任何其他知识产权或所有权的权利；",
    "terms-34":"18)	威胁，跟踪，伤害或骚扰他人，或助长偏执或歧视；",
    "terms-35":"19)	试图操纵 狗急加速VPN 名称，服务或产品；",
    "terms-36":"20)	向未成年人征求或收集个人信息或与未成年人交流；",
    "terms-37":"21)	试图通过黑客攻击，密码挖掘，暴力破解或任何其他方式未经授权访问 狗急加速VPN 服务，用户帐户，连接到 狗急加速VPN 服务的计算机系统或网络；",
    "terms-38":"22)	将 狗急加速VPN 服务用于任何军事目的，包括网络战，武器开发，设计，制造或生产导弹，核，化学或生物武器；",
    "terms-39":"23)	以其他方式侵犯或规避这些条款。",
    "terms-40":"除此之外，长时间占用宽带进行下载导致服务器网络异常，影响到其他用户正常使用的，狗急加速VPN 会采取措施限制滥用 IP 的网速。",
    "terms-41":"为了您的账号安全，非定制账号仅限个人使用，请勿将账号分享给他人，分享给他人造成的损失将由您自己承担。",
    "terms-42":"我们保留自行决定拒绝服务，暂停帐户或限制访问 狗急加速VPN 服务的权利。狗急加速VPN 可以立即实施此类暂停或访问限制，而无需任何指示，通知或退款。我们可能会暂停您的帐户以进行澄清，调查，或要求您解释您的操作并提供其他信息。如果您的帐户已被暂停，则必须与我们联系以获取更多信息。在我们永久终止用户帐户之前，我们可能会在合理的时间内暂停您的用户帐户。",
    "terms-43":"我们没有义务对您执行条款。我们鼓励您让我们知道任何 狗急加速VPN 用户违反这些条款的情况；但是，如果发生此类违规行为，我们可以自行决定采取适当的措施。",
    "terms-44":"免责声明",
    "terms-45":"我们将努力防止网站和服务受到干扰。但是，这些内容是按“原样”和“可用”的基础提供的，我们不保证（明示或暗示）通过本网站或服务提供的任何材料或信息的准确性，或它们对以下内容的适用性：任何特定目的。我们明确否认任何形式的担保，无论是明示的还是暗示的，包括但不限于适销性或针对特定目的的适用性或非侵权的担保。我们不保证本服务将满足您的要求，也不保证其不间断，及时，安全或无错误，也不对任何缺陷进行纠正。您承认自己承担风险和酌情权访问网站和服务。",
    "terms-46":"狗急加速VPN 保留调查我们认为违反这些条款的问题的权利。我们可能会（但无义务）在不另行通知的情况下，自行决定，以任何方式删除，阻止，过滤或限制我们认为是实际或潜在违反本条款所规定限制的任何材料或信息，以及任何其他可能使 狗急加速VPN 或我们的客户承担责任的活动。狗急加速VPN 对我们未能防止此类材料或信息通过“服务”和/或进入您的计算设备的任何故障不承担任何责任。",
    "terms-47":"适用法律",
    "terms-48":"本协议应受新加坡法律的管辖并根据其解释，但其法律冲突规则除外。",
    "terms-49":"服务条款的变更",
    "terms-50":"根据适用的法律和原则，我们可能会不时更改条款服务条款，恕不另行通知。您继续使用本网站或服务即表示您接受我们的隐私政策。",
    "terms-51":"联系我们",
    "terms-52":"如果您对我们的隐私政策以及我们如何处理您的信息有任何疑问，请随时通过以下电子邮件地址与我们联系：7x24@cyberheart.io",
  },
  "tw": {
    "terms-1":"服務條款",
    "terms-2":"狗急加速VPN 有權在任何時間及不需事先通知的情況下修改、取消、增加、替代任何在此列明的條款，而此條款均對本網站的使用者及註冊會員有效力及約束力。因此，您應定期瀏覽本頁。如在本條款作出修訂後仍繼續使用本網站，即被視為接受這些修訂。",
    "terms-3":"當您使用 狗急加速VPN 或註冊成為本平臺會員的同時，您將會被視作接受本條款之所有規則約束。請您在使用本平臺前仔細閱讀所有有關條款。",
    "terms-4":"本條款最新修訂日期為二零二四年六月六日",
    "terms-5":"服務",
    "terms-6":"狗急加速VPN 服務是暫時性的數字網絡通信服務，可增強 Internet 安全性和隱私性。根據您的個人要求和您的全權酌情決定權，我們會自動提供不受監管和不受監控的數據管道-虛擬專用網（VPN）連接-供您專有使用，您對使用 狗急加速VPN 服務負全責。狗急加速VPN 對用戶采取的行動不承擔任何責任，包括對已執行或未執行的損害承擔刑事責任和民事責任。這些條款的其他規定進一步限制了 狗急加速VPN 的責任。",
    "terms-7":"我們授予您使用 狗急加速VPN 服務的許可，但要遵守這些條款中規定的限制。您使 狗急加速VPN 服務的風險自負。狗急加速VPN 服務可以隨時修改，更新，中斷或暫停，恕不另行通知或承擔責任。對於由此造成的對您的任何傷害或其他不利後果，我們不承擔任何責任。狗急加速VPN，其所有者，員工，代理商和其他與 狗急加速VPN 服務相關的人員，對於因使用或向使用其註冊的任何賬戶而產生或引起的任何已執行或未執行的任何形式的損害，概不負責。",
    "terms-8":"狗急加速VPN 保證99.9％的服務正常運行時間。在任何情況下，狗急加速VPN 對超出客戶向 狗急加速VPN 支付的任何損失，金錢或非金錢損失概不負責，並且不包括律師費或法院費用，與任何法律或其他成文法則無關。",
    "terms-9":"知識產權",
    "terms-10":"根據這些條款和條件，我們授予您有限的、非排他的、個人的、不可轉讓的、不可再許可的許可，以：",
    "terms-11":"下載和使用 狗急加速VPN 軟件的副本；",
    "terms-12":"使用 狗急加速VPN 服務，包括但不限於在 狗急加速VPN 軟件或我們的網站上或通過我們的網站提供的產品和服務。根據 狗急加速VPN 服務，本協議未授予您任何其他權利或許可。本文提供的許可在終止之前一直有效。如果您不遵守這些條款，該許可將自動終止。",
    "terms-13":"狗急加速VPN 服務（包括但不限於 狗急加速VPN 軟件，移動應用程序和所有其他項目）由 狗急加速VPN 擁有並擁有版權，並且在全球範圍內受到保護。我們保留對 狗急加速VPN 服務及其任何部分的權利，所有權和利益，包括但不限於所有版權，商標，服務標記，商業秘密和其他知識產權。您不得采取任何行動來危害，限制或以任何方式幹擾我們對 狗急加速VPN 服務的所有權和權利。除非另有說明，否則保留所有權利。",
    "terms-14":"禁止事項",
    "terms-15":"您對 狗急加速VPN 服務的訪問和使用受這些條款以及所有適用法律和法規的約束。對於任何違反任何適用法律或本條款的用戶，我們保留隨時酌情決定隨時終止或阻止其訪問 狗急加速VPN 服務的權利，無論是否另行通知。",
    "terms-16":"在使用 狗急加速VPN 服務時，您自己同意不協助、鼓勵或允許其他人使用 狗急加速VPN 服務：",
    "terms-17":"1)	用於不正當的、禁止的、違法、犯罪或者欺詐活動，包括但不限於端口掃描，發送垃圾郵件，發送選擇接收的電子郵件，掃描開放中繼或開放代理，發送未經請求的電子郵件或發送的任何版本或類型的電子郵件即使通過第三方服務器路由電子郵件，任何彈出窗口啟動，使用被盜信用卡，信用卡欺詐，金融欺詐，加密貨幣欺詐，偽裝，敲詐，勒索，綁架，強奸，謀殺，出售的信用卡被盜，出售被盜商品，提供或出售違禁，軍事和雙重用途商品，提供或出售受控物質，身份盜竊，黑客入侵，偽造，網絡釣魚，任何形式或規模的刮取，數字盜版，知識產權侵權和其他類似活動；",
    "terms-18":"2)	通過 狗急加速VPN 服務以任何方式或形式攻擊，幹擾，獲得未經授權的訪問，拒絕任何其他網絡，計算機或節點的服務；",
    "terms-19":"3)	以任何方式剝削兒童，包括音頻，視頻，攝影，數字內容等；",
    "terms-20":"4)	以任何方式上傳或分發包含病毒，蠕蟲，特洛伊木馬，損壞的文件或任何其他可能損害他人計算機操作的類似軟件或程序的文件；",
    "terms-21":"5)	幹擾或試圖幹擾 狗急加速VPN 服務的正常工作，與 狗急加速VPN 服務有關的任何交易或我們進行的任何其他活動，破壞我們的網站或與 狗急加速VPN 服務相連的任何網絡或繞過任何措施我們可能會用來阻止或限制對 狗急加速VPN 服務的訪問；",
    "terms-22":"6)	以任何未經授權的方式利用 狗急加速VPN 服務，包括但不限於侵入或增加網絡容量；",
    "terms-23":"7)	未經我們事先書面許可，出於任何目的使用任何機器人，蜘蛛，刮板或其他自動方式訪問我們的網站或 狗急加速VPN 服務；",
    "terms-24":"8)	收集或收集有關 狗急加速VPN 服務其他用戶的個人信息；",
    "terms-25":"9)	未經合法利益或同意，或違反任何適用法律而收集或收集個人數據；",
    "terms-26":"10)	采取任何可能會對我們的基礎架構施加不合理或不成比例的巨大負載的措施；",
    "terms-27":"11)	與任何不合理數量的人共享任何數據或其他內容，包括但不限於向大量接收者發送爆炸性通信或與您不認識或不認識您的人共享內容；",
    "terms-28":"12)	代表您是任何 狗急加速VPN 服務（包括其任何功能）的代表或代理商；",
    "terms-29":"13)	將 狗急加速VPN 服務或其任何部分合並到任何其他程序或產品中；",
    "terms-30":"14)	以任何形式或通過任何方式復制或復制 狗急加速VPN 服務的任何部分；",
    "terms-31":"15)	根據 狗急加速VPN 服務修改，翻譯，反向工程，反編譯，反匯編或創建任何衍生作品，包括其任何文件或文檔，或其任何部分，或確定或嘗試確定任何源代碼，算法，方法或 狗急加速VPN 應用程序或其任何部分中包含的技術；",
    "terms-32":"16)	違反一般的道德或道德規範，良好風俗和公平行為規範；",
    "terms-33":"17)	侵犯任何第三方的權利，包括任何違反保密性，個人數據，版權或任何其他知識產權或所有權的權利；",
    "terms-34":"18)	威脅，跟蹤，傷害或騷擾他人，或助長偏執或歧視；",
    "terms-35":"19)	試圖操縱 狗急加速VPN 名稱，服務或產品；",
    "terms-36":"20)	向未成年人征求或收集個人信息或與未成年人交流；",
    "terms-37":"21)	試圖通過黑客攻擊，密碼挖掘，暴力破解或任何其他方式未經授權訪問 狗急加速VPN 服務，用戶帳戶，連接到 狗急加速VPN 服務的計算機系統或網絡；",
    "terms-38":"22)	將 狗急加速VPN 服務用於任何軍事目的，包括網絡戰，武器開發，設計，制造或生產導彈，核，化學或生物武器；",
    "terms-39":"23)	以其他方式侵犯或規避這些條款。",
    "terms-40":"除此之外，長時間占用寬帶進行下載導致服務器網絡異常，影響到其他用戶正常使用的，狗急加速VPN 會采取措施限制濫用 IP 的網速。",
    "terms-41":"為了您的賬號安全，非定制賬號僅限個人使用，請勿將賬號分享給他人，分享給他人造成的損失將由您自己承擔。",
    "terms-42":"我們保留自行決定拒絕服務，暫停帳戶或限制訪問 狗急加速VPN 服務的權利。狗急加速VPN 可以立即實施此類暫停或訪問限制，而無需任何指示，通知或退款。我們可能會暫停您的帳戶以進行澄清，調查，或要求您解釋您的操作並提供其他信息。如果您的帳戶已被暫停，則必須與我們聯系以獲取更多信息。在我們永久終止用戶帳戶之前，我們可能會在合理的時間內暫停您的用戶帳戶。",
    "terms-43":"我們沒有義務對您執行條款。我們鼓勵您讓我們知道任何 狗急加速VPN 用戶違反這些條款的情況；但是，如果發生此類違規行為，我們可以自行決定采取適當的措施。",
    "terms-44":"免責聲明",
    "terms-45":"我們將努力防止網站和服務受到幹擾。但是，這些內容是按“原樣”和“可用”的基礎提供的，我們不保證（明示或暗示）通過本網站或服務提供的任何材料或信息的準確性，或它們對以下內容的適用性：任何特定目的。我們明確否認任何形式的擔保，無論是明示的還是暗示的，包括但不限於適銷性或針對特定目的的適用性或非侵權的擔保。我們不保證本服務將滿足您的要求，也不保證其不間斷，及時，安全或無錯誤，也不對任何缺陷進行糾正。您承認自己承擔風險和酌情權訪問網站和服務。",
    "terms-46":"狗急加速VPN 保留調查我們認為違反這些條款的問題的權利。我們可能會（但無義務）在不另行通知的情況下，自行決定，以任何方式刪除，阻止，過濾或限制我們認為是實際或潛在違反本條款所規定限制的任何材料或信息，以及任何其他可能使 狗急加速VPN 或我們的客戶承擔責任的活動。狗急加速VPN 對我們未能防止此類材料或信息通過“服務”和/或進入您的計算設備的任何故障不承擔任何責任。",
    "terms-47":"適用法律",
    "terms-48":"本協議應受新加坡法律的管轄並根據其解釋，但其法律沖突規則除外。",
    "terms-49":"服務條款的變更",
    "terms-50":"根據適用的法律和原則，我們可能會不時更改條款服務條款，恕不另行通知。您繼續使用本網站或服務即表示您接受我們的隱私政策。",
    "terms-51":"聯系我們",
    "terms-52":"如果您對我們的隱私政策以及我們如何處理您的信息有任何疑問，請隨時通過以下電子郵件地址與我們聯系：7x24@cyberheart.io",
  }
}
export default termsLanguages

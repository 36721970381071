<template>
  <div id="root">
    <Access></Access>
    <HeadUp></HeadUp>
    <div ref="main" id="main" class="main" :style="isFixed ? mainStyleFixed : mainStyleStatic">
      <CenterParcel :isFixed="isFixed"/>
    </div>
    <Footer :class="isFixed ? 'bottom-fixed' : 'bottom-static'"></Footer>
  </div>
</template>

<script>

  import utils from "./utils/utils"
  import { useStore } from 'vuex'
  import { nextTick, onMounted, ref } from 'vue'

  import useTrace from './hooks/useTrace'

  import HeadUp from './components/Head.vue'
  import Footer from './components/Footer.vue'
  import CenterParcel from './components/CenterParcel.vue'
  import Access from './components/Access.vue'
  import {getDomain} from "@/config";

  export default {
    name: 'App',
    components: {
      Access,
      CenterParcel,
      HeadUp,
      Footer
    },
    // props; context: attrs、slots、emit
    // setup()内使用响应式ref数据时，需要通过.value获取
    setup () {
      let useStores = useStore()
      let store = useStores.state

      function setTitle (lang) {
        document.title = store.languages[lang]['title']
      }

      function getTitle () {
        this.$http.get(`${getDomain('oc')}/api/website/vue/lpt-title`)
          .then(data => {
            const _data = data.data.obj
            Object.keys(_data).forEach(k => {
              store.languages[k]['title'] = _data[k]
            })
            setTitle(store.lang)
          })
          .catch(err => {
            console.log(err)
          })
      }

      function getDownloads() {
        const ch = utils.getQuery('ch') || window.env.channel
        const pkg = utils.getQuery('pkg')
        utils.getDownloads(ch, pkg).then(res => {
          useStores.commit('setDownloads', res.data)
        })
      }

      let main = ref(null)
      let isFixed = ref(false)
      let mainStyleFixed = ref({
        position: 'absolute',
        top: 0,
      })
      let mainStyleStatic = ref({
        position: 'static',
      })

      function footerLayout () {
        isFixed.value = false
        nextTick(() => {
          isFixed.value = false
          mainStyleFixed.value.bottom = store.footerHeight - 1 + 'px'
          let allHeight = window.innerHeight
          setTimeout(() => {
            isFixed.value = allHeight - store.footerHeight >= main.value.offsetHeight
          }, 10)
        })
      }
      window.footerLayout = footerLayout
      window.addEventListener('resize', () => {
        footerLayout()
      })

      useTrace("#root")
      
      onMounted(() => {
        nextTick(() => {
          window.addEventListener('load', () => {
            footerLayout()
          })
        })
        utils.onTrace()
      })

      return {
        useTrace,
        getTitle,
        setTitle,
        getDownloads,
        store,
        isFixed,
        main,
        mainStyleFixed,
        mainStyleStatic
      }
    },
    created () {
      this.getDownloads()
      this.getTitle()
      let lang = utils.getCookie('lang')
      useStore().commit('change', (lang || 'zh'))
      this.setTitle(this.store.lang)
      let html = document.getElementsByTagName('html')[0]
      utils.bindBGCEvent(html, '#a1eeff', '#000000')
    }
  }
</script>
<style type="text/css">
  * {
    padding: 0;
    margin: 0;

  }

  body {
    font-family: Roboto, sans-serif;
    font-weight: 700;
    height: 100%;
    z-index: -1;
    width: 100%;
  }

  body, html {
    font-size: 12px;
  }

  a, a:link, a:visited {
    text-decoration: none;
    color: inherit;
  }

  ul {
    list-style: none;
  }

  #app {
    background-color: #a1eeff;
  }

  .main {
    width: 100%;
    background-color: transparent;
  }

  .bottom-fixed {
    position: fixed !important;
    bottom: 0 !important;
  }

  .bottom-static {
    position: static !important;
  }

  .intro a:hover,
  .intro a:link,
  .intro a:visited,
  .intro a:active {
    color: #0066FF;
    text-decoration: underline;
  }
</style>

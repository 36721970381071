<template>
  <div class="jump-to-download ">
    <a id="onedow">
      <span class="download-button">
      {{store.languages[store.lang]['download-now'] || '立即下载'}}
      </span>
    </a>
  </div>
</template>

<script>
  import { useRouter } from 'vue-router'
  import { useStore } from 'vuex'
  import { onMounted } from 'vue'
  import utils from "../utils/utils"

  export default {
    name: 'AutoDownload',
    components: {},
    setup () {
      let store = useStore().state
      let router = useRouter()

      function initDownload () {
        let downloadUrl = `/${store.env.app}/apks/${store.env.version}/${store.env.app}-${store.env.version}-release-${store.downloads || store.env.channel}.apk`
        let isAndroid = window.navigator.userAgent.toLowerCase().indexOf('android') > -1;
        let isIos = (window.navigator.userAgent.toLowerCase().indexOf('iphone') > -1);
        let isIpad = /(iPad|iPod|iOS)/i.test(navigator.userAgent) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
        let onedow = document.getElementById('onedow')
        if (isIpad) {
          onedow.addEventListener('touchend', function () {
            document.documentElement.scrollTop = document.body.scrollTop = 0;
            router.push({name:'ios'})
          })
        } else {
          let autoToPage = function () {
            document.documentElement.scrollTop = document.body.scrollTop = 0;
            if (isAndroid) {
              utils.statsDownload("android")
              if (window.install) {
                utils.download(downloadUrl)
                return window.install('', ['download-button-android'])
              } else {
                utils.download(downloadUrl)
                setTimeout(function () {
                  router.push({name:'android'})
                }, 2000)
              }
            } else if (isIos) {
              router.push({name:'ios'})
            } else {
              document.documentElement.scrollTop = document.body.scrollTop = 0;
            }
          }
          onedow.addEventListener('click', autoToPage)
        }
      }


      onMounted(()=>{
        initDownload()
      })

      return {
        store
      }
    }
  }
</script>

<style scoped>
  .jump-to-download a {
    display: flex;
    justify-content: center;
    border-radius: 2.5rem;
    text-align: center;
    width: 18rem;
    margin: 0 auto 3rem auto;
    color: white;
    background-color: #CC1424;
    cursor: pointer;
  }

  .jump-to-download a span {
    font-size: 1.5rem;
    line-height: 4rem;
  }

  .jump-to-download {
    display: flex;
    justify-content: center;
    align-items: center;
  }


  /*Mobile*/

  @media all and (max-width: 366px) and (min-width: 150px) {
    .jump-to-download {
      margin-top: 5rem;
    }
  }

  @media all and (max-width: 500.999px) and (min-width: 366px) {

    .jump-to-download {
      margin-top: 7rem;
    }
  }

  /*Tablet*/

  @media all and (max-width: 800px) and (min-width: 501px) {

    .jump-to-download {
      margin-top: 7rem;
    }
  }

</style>

<template>
  <div class="show-service">
    <div class="show-service-why">
      <span>{{store.languages[store.lang]['choose-us'] || '为什么选择狗急加速'}}</span>
    </div>
    <div class="show-service-info-parent">
      <div class="show-service-info">
        <div>
          <div><img src="@/assets/images/share.png" alt=""></div>
          <div>
            <span>100+</span>
            <span>{{store.languages[store.lang]['servers'] || '国际节点'}}</span>
          </div>
        </div>
        <div>
          <div><img src="@/assets/images/public.png" alt=""></div>
          <div>
            <span>20+</span>
            <span>{{store.languages[store.lang]['countries'] || '国家地区'}}</span>
          </div>
        </div>
        <div>
          <div><img src="@/assets/images/groups.png" alt=""></div>
          <div>
            <span>100,000+</span>
            <span>{{store.languages[store.lang]['users'] || '满意用户'}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { useStore } from 'vuex'

  export default {
    name: 'ChooseUs',
    components: {},
    setup () {
      let store = useStore().state
      return { store }
    },
  }
</script>

<style scoped>
  .show-service-why {
    text-align: center;
    font-size: 2rem;
    margin: 3rem 0;
    font-weight: 800;
  }

  .show-service-info img {
    width: 48px;
    height: 48px;
    margin-right: 2rem;
  }

  .show-service-info, .show-service-info > div {
    display: flex;
    font-size: 1.6rem;
    justify-content: space-between;
    align-items: center;
  }

  .show-service {
    padding: 3rem 15%;
    width: 70%;
  }

  .show-service span {
    display: block;
  }

  .show-service-info div div span:first-child {
    font-size: 3rem;
  }

  .show-service-info div div span:last-child {
    color: #cc1424;
  }

  @media all and (max-width: 366px) and (min-width: 150px) {
    .show-service {
      padding: 3rem 0;
      width: 100%;
    }

    .show-service-info > div:nth-child(n+2) {
      margin-top: 3rem;
    }

    .show-service-info-parent {
      display: flex;
      justify-content: center;
    }

    .show-service-info {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  @media all and (max-width: 500.999px) and (min-width: 366px) {
    .show-service {
      padding: 3rem 0;
      width: 100%;
    }

    .show-service-info > div:nth-child(n+2) {
      margin-top: 3rem;
    }

    .show-service-info-parent {
      display: flex;
      justify-content: center;
    }

    .show-service-info {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  /*Tablet*/

  @media all and (max-width: 800px) and (min-width: 501px) {
    .show-service {
      padding: 3rem 0;
      width: 100%;
    }

    .show-service-info > div:nth-child(n+2) {
      margin-top: 3rem;
    }

    .show-service-info-parent {
      display: flex;
      justify-content: center;
    }

    .show-service-info {
      flex-direction: column;
      align-items: flex-start;
    }

  }

</style>

import { createRouter, createWebHashHistory } from "vue-router";
import Banner from "../components/Banner";
// import IOS from "../components/IOS";

const routes = [
  {
    path: "/",
    name: "banner",
    component: Banner,
  },
  {
    path: "/ios",
    name: "ios",
    component: () => import("../components/IOS"),
  },
  {
    path: "/macos",
    name: "macos",
    component: () => import("../components/MacOS"),
  },
  {
    path: "/android",
    name: "android",
    component: () => import("../components/Android"),
  },
  {
    path: "/windows",
    name: "windows",
    component: () => import("../components/Windows"),
  },
  {
    path: "/ios-testflight",
    name: "ios-testflight",
    component: () => import("../components/IOSFestflight"),
  },
  {
    path: "/ios-testflight-faq",
    name: "ios-testflight-faq",
    component: () => import("../components/IOSTestflightFaq"),
  },
  {
    path: "/ios-appstore-faq",
    name: "ios-appstore-faq",
    component: () => import("../components/IOSAppstoreFaq"),
  },
  {
    path: "/macos-appstore-faq",
    name: "macos-appstore-faq",
    component: () => import("../components/IOSAppstoreFaq"),
  },
  {
    path: "/ios-testflight-upgrade",
    name: "ios-testflight-upgrade",
    component: () => import("../components/IOSTestflightUpgrade"),
  },
  {
    path: "/ios-appstore-upgrade",
    name: "ios-appstore-upgrade",
    component: () => import("../components/IOSAppstoreUpgrade"),
  },
  {
    path: "/macos-appstore-upgrade",
    name: "macos-appstore-upgrade",
    component: () => import("../components/MacOSAppstoreUpgrade"),
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});
router.afterEach(() => {
  if(window.footerLayout) window.footerLayout()
})
export default router;

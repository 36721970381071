<template>
  <div ref="footer" class="footer">
    <div class="footer-text">
      <div class="footer-text-row">
        <div>
          <div>
            <div>{{store.languages[store.lang]['about-us'] || '关于我们'}}</div>
            <a :href="`terms_of_service_${store.lang}.html?from=${from}`">{{store.languages[store.lang]['terms-service'] || '服务条款'}}</a>
            <a :href="`privacy_policy_${store.lang}.html?from=${from}`">{{store.languages[store.lang]['privacy-policy'] || '隐私政策'}}</a>
          </div>
          <div class="footer-text-img">
            <a :href="contactTwitter" target="_blank"><img src="@/assets/images/Twitter@2x.png"/></a>
            <a :href="contactFacebook"
               target="_blank"><img src="@/assets/images/facebook@2x.png"/></a>
            <a :href="contactInstagram" target="_blank"><img src="@/assets/images/instagram@2x.png"/></a>
          </div>
        </div>

<!--        <div>-->
<!--          <div>{{store.languages[store.lang]['multiPlatformDownload'] || '多平台下载'}}</div>-->
<!--          <a @click="downloadInstall(['download-button-android'], $event)"-->
<!--             class="download-button-android"-->
<!--             :href="`/${store.env.app}/apks/${store.env.version}/${store.env.app}-${store.env.version}-release-${store.downloads || store.env.channel}.apk`">-->
<!--            Android-->
<!--          </a>-->
<!--          <a href="/index.html#/ios">-->
<!--            iOS-->
<!--          </a>-->
<!--          <a href="/index.html#/macos">-->
<!--            macOS-->
<!--          </a>-->
<!--          <a @click="downloadInstall(['download-button-windows'], $event)"-->
<!--             class="download-button-windows"-->
<!--             :href="`/${store.env.app}/exes/${store.env.version}/${store.env.app}-${store.env.version}-release-${store.env.channel}.exe`">-->
<!--            Windows-->
<!--          </a>-->
<!--        </div>-->

        <div>
          <div>{{store.languages[store.lang]['help-center'] || '帮助中心'}}</div>
          <a :href="`/blog/?from=${from}`" class="help-mail">{{store.languages[store.lang]['common-problem'] || '常见问题'}}</a>
          <a :href="`mailto:${contactEmail}?subject=${contactSubject}&body=${contactBody}`" class="help-mail">{{contactEmail}}</a>

          <a @click="toggle">{{store.languages[store.lang]['online-customer'] || '在线客服'}}</a>
<!--          <a target="_blank" :href="contactSkype">Skype</a>-->
<!--          <a target="_blank" :href="contactWhatsApp">WhatsApp</a>-->
          <a target="_blank" :href="contactTelegram">Telegram</a>
<!--          <a target="_blank" :href="contactPotato">Potato</a>-->
        </div>
        <div>
          <div>{{store.languages[store.lang]['partners'] || '合作'}}</div>
          <a href="mailto:biz@cyberheart.io">biz@cyberheart.io</a>
        </div>
      </div>
      <div class="footer-text-column">
        <div>
          <div>
            <div>{{store.languages[store.lang]['about-us'] || '关于我们'}}</div>
            <a :href="`terms_of_service_${store.lang}.html?from=${from}`">{{store.languages[store.lang]['terms-service'] || '服务条款'}}</a>
            <a :href="`privacy_policy_${store.lang}.html?from=${from}`">{{store.languages[store.lang]['privacy-policy'] || '隐私政策'}}</a>
          </div>
          <div class="footer-text-img">
            <a :href="contactTwitter" target="_blank"><img src="@/assets/images/Twitter@2x.png"/></a>
            <a :href="contactFacebook"
               target="_blank"><img src="@/assets/images/facebook@2x.png"/></a>
            <a :href="contactInstagram" target="_blank"><img src="@/assets/images/instagram@2x.png"/></a>
          </div>


          <div>
            <div>{{store.languages[store.lang]['partners'] || '合作'}}</div>
            <a href="mailto:biz@cyberheart.io">biz@cyberheart.io</a>
          </div>
        </div>
        <div>
<!--          <div class="footer-text-multi-platform">-->
<!--            <div>{{store.languages[store.lang]['multiPlatformDownload'] || '多平台下载'}}</div>-->
<!--            <a @click="downloadInstall(['download-button-android'], $event)"-->
<!--               class="download-button-android"-->
<!--               :href="`/${store.env.app}/apks/${store.env.version}/${store.env.app}-${store.env.version}-release-${store.downloads || store.env.channel}.apk`">-->
<!--              Android-->
<!--            </a>-->
<!--            <a href="/index.html#/ios">-->
<!--              iOS-->
<!--            </a>-->
<!--            <a href="/index.html#/macos">-->
<!--              macOS-->
<!--            </a>-->
<!--            <a @click="downloadInstall(['download-button-windows'], $event)"-->
<!--               class="download-button-windows"-->
<!--               :href="`/${store.env.app}/exes/${store.env.version}/${store.env.app}-${store.env.version}-release-${store.env.channel}.exe`">-->
<!--              Windows-->
<!--            </a>-->
<!--          </div>-->

          <div class="space-bottom">
            <div>{{store.languages[store.lang]['help-center'] || '帮助中心'}}</div>
            <a :href="`/blog/?from=${from}`" class="help-mail">{{store.languages[store.lang]['common-problem'] || '常见问题'}}</a>
            <a :href="`mailto:${contactEmail}?subject=${contactSubject}&body=${contactBody}`" class="help-mail">{{contactEmail}}</a>
            <a @click="toggle">{{store.languages[store.lang]['online-customer'] || '在线客服'}}</a>
            <!--            <a target="_blank" :href="contactSkype">Skype</a>-->
            <!--            <a target="_blank" :href="contactWhatsApp">WhatsApp</a>-->
            <a target="_blank" :href="contactTelegram">Telegram</a>
            <!--            <a target="_blank" :href="contactPotato">Potato</a>-->
          </div>
        </div>
      </div>
    </div>

    <div>
      <hr/>
    </div>
    <div class="copyright">
      <ul>
        <li></li>
        <li>
          <span class="lang">
            © {{new Date().getFullYear() || '2024'}} {{store.languages[store.lang]['product'] || '狗急加速'}}
          </span>
          <br class="copyright-line">
          <span class="lang">
          &nbsp;All Rights Reserved.&nbsp;
        </span>
        </li>
        <li class="lang">Singapore</li>
      </ul>
    </div>
  </div>
</template>

<script>
  import { onMounted, ref } from 'vue'
  import { useStore } from 'vuex'
  import utils from "../utils/utils"
  import {getDomain} from "@/config";
  import {useRouter} from "vue-router";

  export default {
    name: 'Footer',
    components: {},
    setup () {
      let useStores = useStore()
      let store = useStores.state
      let router = useRouter()

      function toggle () {
        let widgetOpen = window.sessionStorage.getItem('ZD-widgetOpen');
        try {
          if (widgetOpen && widgetOpen === 'true') {
            window.zE('messenger', 'close');
          } else {
            window.zE('messenger', 'open');
          }
        } catch (e) {
          console.log('toggle fail')
        }
      }

      let footer = ref(null)
      function setFooterHeight(value) {
          useStores.commit('setHeight' , ['footerHeight', value])
      }
      onMounted(()=>{
        setFooterHeight(footer.value.offsetHeight)
        window.addEventListener('resize', ()=> {
          setFooterHeight(footer.value.offsetHeight)
        })
      })

      let contactSubject = ref('')
      let contactBody = ref('')
      let contactEmail = ref('7x24@cyberheart.io')

      let contactTwitter = ref('https://twitter.com/gojvpn')
      let contactFacebook = ref('https://www.facebook.com/%E7%8B%97%E6%80%A5vpn%E5%8A%A0%E9%80%9F%E5%99%A8-105141698051464"')
      let contactInstagram = ref('https://www.instagram.com/vpngogojump/')

      let contactSkype = ref('https://join.skype.com/invite/wAb5elFleDN3')
      let contactWhatsApp = ref('https://wa.me/qr/NSSC3JYD7CHAJ1')
      let contactTelegram = ref('https://t.me/goj123')
      let contactPotato = ref('https://lynnconway.me/Goujijs')

      let from = ref(utils.getQuery('from') || 'index')

      if (window.env && window.env.channel) from.value = window.env.channel
      if (window.location.href.includes('index')) from.value = 'index'


      function ReplaceContactInfo () {
        this.$http.get(`${getDomain('oc')}/api/website/vue/help_center`)
        .then((data)=>{
         let _data = data.data['obj']
         if(_data['email-subject']) contactSubject.value = _data['email-subject']
         if(_data['email-body']) contactBody.value = _data['email-body']
         if(_data['email']) contactEmail.value = _data['email']

         if(_data['twitter']) contactTwitter.value = _data['twitter']
         if(_data['facebook']) contactFacebook.value = _data['facebook']
         if(_data['instagram']) contactInstagram.value = _data['instagram']

         if(_data['skype']) contactSkype.value = _data['skype']
         if(_data['whatsApp']) contactWhatsApp.value = _data['whatsApp']
         if(_data['telegram']) contactTelegram.value = _data['telegram']
         if(_data['potato']) contactPotato.value = _data['potato']
        })
        .catch(e=>{
          console.log(e)
        })
      }

      function downloadInstall (className, event) {
        let resp = window.install('', className)
        if (!resp) event.preventDefault()
        return resp
      }

      return{
        router,
        downloadInstall,
        footer,
        from,
        store,
        toggle,
        ReplaceContactInfo,
        contactSubject,
        contactBody,
        contactEmail,
        contactTwitter,
        contactFacebook,
        contactInstagram,
        contactSkype,
        contactWhatsApp,
        contactTelegram,
        contactPotato,
      }
    },
    created () {
      this.ReplaceContactInfo()
    }
  }
</script>

<style scoped>

  .footer {
    bottom: 0;
    background-color: black;
    color: white;
    padding: 15px 0 17px 0;
    width: 100%;
    font-size: 1.2rem;
    font-weight: normal;
    position: static;
  }
  .footer > div {
    width: 70%;
    margin: auto 15%;
  }

  .footer-text-row, .footer-text-column {
    display: flex;
    justify-content: space-between;
  }
  .footer-text-row>div, .footer-text-column div {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }
  .footer-text-row>div:first-child>div:first-child,.footer-text-column>div:first-child>div:first-child {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }

  .footer div {
    line-height: 2rem;
  }
  .footer-text-row div, .footer-text-column div {
    font-weight: 900;
    line-height: 3.5rem;
    font-size: 1.6rem;
    color: #666;
  }

  .footer a {
    color: white;
    text-decoration: none;
    cursor: pointer;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 2rem;
  }
  .footer-text-row br {
    display: none;
  }
  .footer-text-column {
    display: none;
    justify-content: space-between;
  }

  .footer hr {
    margin-top: 2.5rem;
    border-width: .5px;
    border-color: #404040;
  }

  .footer > div:last-child, .copyright {
    margin-top: 1rem;
    text-align: center;
    line-height: 2rem;
  }
  .footer-text .footer-text-multi-platform {
    margin-bottom: 1rem;
  }
  .footer-text .footer-text-img a {
    width: 2.5rem;
    height: 2.5rem;
  }
  .footer-text .footer-text-img {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 1.5rem;
  }
  .footer-text img {
    width: 100%;
    height: 100%;
  }
  .footer-text .footer-text-img>a:not(:last-child) {
    margin-right: .8rem;
  }
  .footer-text .footer-text-img>a {
    margin-top: 1rem;
  }
  .space-bottom {
    margin-bottom: 1.5rem;
  }
  .copyright-line {
    display: none;
  }

  .footer ul {
    list-style: none
  }

  @media all and (max-width: 366px) and (min-width: 150px) {
    .footer-text-row {
      display: none;
    }
    .footer-text-column {
      display: flex;
    }
    .footer-text-row br {
      display: inherit;
    }
    .copyright-line {
      display: inline-block;
    }

  }

  @media all and (max-width: 530.999px) and (min-width: 366px) {
    .footer-text-row {
      display: none;
    }
    .footer-text-column {
      display: flex;
    }
    .footer-text-row br {
      display: inherit;
    }
    .copyright-line {
      display: inline-block;
    }
  }

  @media all and (max-width: 800px) and (min-width: 531px) {

    .footer-text-row br {
      display: inherit;
    }
    .copyright-line {
      display: inline-block;
    }
  }

</style>

import { ref } from "vue";
import Clipboard from "clipboard";

const traceInfo = ref("{}");

function encode(str) {
  let encodedStr = '';
  for (let i = 0; i < str.length; i++) {
    const charCode = str.charCodeAt(i);
    const charCodeStr = String(charCode).padStart(3, '0');
    for (let j = 0; j < 3; j++) {
      const numChar = Number(charCodeStr[j]) + 20;
      encodedStr += String.fromCharCode(numChar + '');
    }
  }
  return encodedStr;
}

function decode(encodedStr) {
  let decodedStr = '';
  let charCodeStr = '';
  for (let i = 0; i < encodedStr.length; i ++) {
    let _m = encodedStr.charCodeAt(i)
    const m = Number(_m) - 20;
    charCodeStr += m;
    if (i !== 0 && (i+1) % 3 === 0) {
      const charCode = parseInt(charCodeStr);
      const char = String.fromCharCode(charCode);
      decodedStr += char;
      charCodeStr = '';
    }
  }
  return decodedStr;
}

export default function useTrace(elid) {
  const clipboard = new Clipboard(elid, {
    text: function () {
      return encode(traceInfo.value);
    },
  });
  clipboard.on("success", function (e) {
    e.clearSelection();
  });

  clipboard.on("error", function (e) {
    e.clearSelection();
  });

  return { traceInfo };
}


export { traceInfo };

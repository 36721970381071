<template>
  <div class="waiter-parent">
    <div class="recommended">
      {{store.languages[store.lang]['recommended'] || '我们为您带来优质的服务'}}
    </div>
    <div class="waiter">
      <div>
        <img src="@/assets/images/feature_video.png" alt="">
        <span>{{store.languages[store.lang]['waiter-title-1'] || '解锁流媒体服务，全球视频平台都可以流畅观看'}}</span>
        <p>
          {{store.languages[store.lang]['waiter-content-1'] || '解除地域限制，在世界任何地区，都能流畅观看 NETFLIX、HULU、HBO、AbemaTV、Disney 等流媒体内容，让您能自由地访问自己喜欢的内容。'}}</p>
      </div>
      <div>
        <img src="@/assets/images/feature_worldwide.png" alt="">
        <span>{{store.languages[store.lang]['waiter-title-2'] || '流畅使用部分地区内，无法使用的应用和服务'}}</span>
        <p>{{store.languages[store.lang]['waiter-content-2'] || '狗急加速可以让您自由使用 Twitter，Instagram 和Facebook等应用，7x24小时永不掉线，方便和全世界不同地区的朋友进行互动。'}}</p>
      </div>
      <div>
        <img src="@/assets/images/feature_anonymous.png" alt="">
        <span>{{store.languages[store.lang]['waiter-title-3'] || '无日志、匿名访问应用和网站，保护您的隐私'}}</span>
        <p>{{store.languages[store.lang]['waiter-content-3'] || '狗急加速承诺永远坚持无日志记录的政策，确保您在所有应用程序和平台的访问数据，完全匿名且无记录，更大程度的保护您的隐私。'}}</p>
      </div>
      <div>
        <img src="@/assets/images/feature_low_latency.png" alt="">
        <span>{{store.languages[store.lang]['waiter-title-4'] || '自研专利专线，确保高速低延迟'}}</span>
        <p>{{store.languages[store.lang]['waiter-content-4'] || '狗急加速所有类型的服务器节点，都使用了自研的专利加速技术，更有超快 S-IPLC 专线，确保无论是平日还是高峰，都能保持低延迟无卡顿的使用体验。'}}</p>
      </div>
      <div>
        <img src="@/assets/images/feature_protocol.png" alt="">
        <span>{{store.languages[store.lang]['waiter-title-5'] || '多协议支持，确保稳定不掉链'}}</span>
        <p>{{store.languages[store.lang]['waiter-content-5'] || '使用 v2ray，trojan 等多种协议，保证网络节点不受大部分事件影响，无论何时，都能保证高速稳定的运行。'}}</p>
      </div>
      <div>
        <img src="@/assets/images/feature_safe.png" alt="">
        <span>{{store.languages[store.lang]['waiter-title-1'] || '最高安全 CDC 加密技术'}}</span>
        <p>{{store.languages[store.lang]['waiter-content-6'] || '您的任何连接访问，都将受到保护。狗急加速最高安全 CDC 加密技术，可以确保您的任何数据，都不会被窥探到，尽可放心使用。'}}</p>
      </div>
    </div>
  </div>
</template>

<script>
  import { useStore } from 'vuex'

  export default {
    name: 'Offer',
    components: {},
    setup () {
      let useStores = useStore()
      let store = useStores.state

      return {
        store
      }
    },
  }
</script>

<style scoped>

  .waiter {
    justify-content: space-between;
    flex-wrap: wrap;

  }


  .waiter div {
    position: relative;
    color: black;
    text-align: center;
  }

  .waiter div img {
    margin: 2rem 0;
    display: inline-block;
    width: 80%;
  }

  .waiter div p {
    text-align: left;
    margin-top: 1rem;
    color: #848383;
    line-height: 2rem;
    font-size: 1.2rem;
    font-weight: 500;
  }

  .waiter div span {
    text-align: left;
    display: inline-block;
    width: 100%;
    height: 5rem;
    line-height: 2.5rem;
    font-size: 1.6rem;
    font-family: PingFangSC-Medium, PingFang SC;

  }

  .recommended {
    font-size: 2rem;
    line-height: 6rem;
    text-align: center;
    color: black;
    padding: 2rem 0;
  }

  /*Mobile*/

  @media all and (max-width: 366px) and (min-width: 150px) {
    .recommended {
      font-size: 2rem;
    }

    .waiter {
      display: block;
      width: 90%;
      margin-left: 5%;
      margin-right: 5%;
    }

    .waiter > div {
      width: 100%;
    }
  }

  @media all and (max-width: 500.999px) and (min-width: 366px) {

    .recommended {
      font-size: 2rem;
    }

    .waiter {
      display: block;
      width: 90%;
      margin-left: 5%;
      margin-right: 5%;
    }

    .waiter > div {
      width: 100%;
    }

  }

  /*Tablet*/

  @media all and (max-width: 800px) and (min-width: 501px) {

    .waiter {
      display: flex;
      width: 90%;
      margin-left: 5%;
      margin-right: 5%;
    }

    .waiter > div {
      width: 49%;
    }
  }

  @media all and (max-width: 1399px) and (min-width: 800.9px) {

    .waiter {
      display: flex;
      width: 75%;
      margin-left: 12.5%;
      margin-right: 12.5%;
    }

    .waiter > div {
      width: 32%;
    }
  }

  /*Mid Screen*/

  @media all and (max-width: 1600px) and (min-width: 1400px) {
    .waiter {
      display: flex;
      width: 75%;
      margin-left: 12.5%;
      margin-right: 12.5%;
    }

    .waiter > div {
      width: 32%;
    }
  }

  /*Large Screen*/

  @media all and (max-width: 2100px) and (min-width: 1601px) {

    .waiter {
      display: flex;
      width: 75%;
      margin-left: 12.5%;
      margin-right: 12.5%;
    }

    .waiter > div {
      width: 32%;
    }
  }

  /*Extra Large Screen*/

  @media all and (min-width: 2101px) {
    .waiter {
      display: flex;
      width: 75%;
      margin-left: 12.5%;
      margin-right: 12.5%;
    }

    .waiter > div {
      width: 32%;
    }
  }

</style>

<template>
  <HeadUp :toHome="toHome" :change="change"></HeadUp>
  <div class="secrecy viewport">
    <div style="height: 5.58rem"></div>
    <div>
      <h2>{{ store.policyLanguages[store.lang]['policy-1'] || 'Privacy Policy'}}</h2>

      <p>{{ store.policyLanguages[store.lang]['policy-2'] || 'GoGoJumpVPN respect our user’s privacy and make every effort to protect the privacy of our Users. We want you to understand what information we collect, what we don’t collect, and how we collect, use, and store information. We do not collect logs of your activity, including no logging of browsing history, traffic destination, data content, or DNS queries. Also, we never store connection logs, meaning no logs of your IP address, connection timestamp, or session duration.'}}</p>

      <p>{{ store.policyLanguages[store.lang]['policy-3'] || 'Last updated on Jun 6, 2024.'}}</p>
    </div>
    <div>
      <h2>{{ store.policyLanguages[store.lang]['policy-4'] || 'Acceptance'}}</h2>
      <p>{{ store.policyLanguages[store.lang]['policy-5'] || "We are committed to protecting your privacy. Your understanding and compliance with GoGoJumpVPN's Privacy Policy will help us provide you with an efficient and satisfying service experience. Our Privacy Policy covers any personal information that GoGoJumpVPN obtains from you when you use the services provided on GoGoJumpVPN."}}</p>

      <p>{{ store.policyLanguages[store.lang]['policy-6'] ||  'Services provided on GoGoJumpVPN, you consent to the terms of our Privacy Policy, including our use of information that is collected or submitted as described in our Privacy Policy.'}}</p>

    </div>
    <div>
      <h2>{{ store.policyLanguages[store.lang]['policy-52'] || 'User Account Deletion'}}</h2>
      <p>{{ store.policyLanguages[store.lang]['policy-53'] || "You can delete your GoGoJumpVPN account at any time. At the same time, we will delete the relevant data. Please note that deleted accounts and data cannot be recovered, but you can re-register an account and continue to use GoGoJumpVPN services."}}</p>

      <p>{{ store.policyLanguages[store.lang]['policy-54'] ||  'How to delete your account: Please visit the left sidebar of the APP, select "My Account", select "Delete Account" at the bottom of the page, and follow the instruction.'}}</p>

    </div>
    <div>
      <h2>{{ store.policyLanguages[store.lang]['policy-7'] || 'Information We Collect'}}</h2>

      <p>{{ store.policyLanguages[store.lang]['policy-8'] || 'When you register on the GoGoJumpVPN you can choose to fill in the email, but it is not necessary. It will not affect registration if you do not fill in the email. We will store this information, but will not share it with any third parties except as necessary to provide the features of the Service.'}}</p>

      <p>{{ store.policyLanguages[store.lang]['policy-9'] ||  'For example, We may use your information when analyzing user data with third-party platform tools. We will use your contact details to send you notifications about the Service and to respond to customer support requests. You may access and change your personal information by logging in to the GoGoJumpVPN.'}}</p>

      <p>{{ store.policyLanguages[store.lang]['policy-10'] || 'GoGoJumpVPN uses your email address for the following reasons:'}}</p>
      <ul>
        <li>{{ store.policyLanguages[store.lang]['policy-11'] || 'To provide links to our Site, including password reset emails;'}}</li>
        <li>{{ store.policyLanguages[store.lang]['policy-12'] || 'To communicate with you about your VPN services or respond to your communications;'}}</li>
        <li>{{ store.policyLanguages[store.lang]['policy-13'] || 'To send marketing information, You may choose to not receive Marketing emails by following the opt-out procedure described in these emails.'}}</li>
      </ul>
      <p>{{ store.policyLanguages[store.lang]['policy-14'] || 'GoGoJumpVPN uses your personal information only for the purposes listed in this Privacy Policy, and we do not sell your personal information to third parties.'}}</p>
    </div>
    <div>
      <h2>{{ store.policyLanguages[store.lang]['policy-15'] || 'International Data Transfers'}}</h2>
      <p>{{ store.policyLanguages[store.lang]['policy-16'] || 'Our service is spread globally, and as such, your data travels through our global servers, which might not or might be outside of your country of residence. We rely on some third-party service providers to enable us to provide you our services. Whenever we transfer your information, we take steps to protect it.'}}</p>

      <p>{{ store.policyLanguages[store.lang]['policy-17'] || 'You acknowledge and understand that your information will be Information transmission as it is necessary to provide our services and live up to our Terms of Service.'}}</p>

    </div>
    <div>
      <h2>{{ store.policyLanguages[store.lang]['policy-18'] || 'Information Sercurity'}}</h2>
      <p>{{ store.policyLanguages[store.lang]['policy-19'] || 'We urge you to protect your own privacy. We recommend that you do not share your GoGoJumpVPN password with anyone or in any unsolicited phone call or email.'}}
      </p>
      <p>{{ store.policyLanguages[store.lang]['policy-20'] || 'All public and private keys and passwords are secured securely using strong cryptographic algorithms where necessary. Appropriate security measures are taken for the protection of this information against unauthorized access, unauthorized alteration, destruction or disclosure of data. Access to personal information by GoGoJumpVPN employees, contractors and agents is restricted. These officials require that information in order to operate, develop or improve our services but are bound by confidentiality obligations. They may be subjected to disciplinary action, which includes termination and criminal prosecution, upon failing to meet these obligations.'}}</p>
    </div>
    <div>
      <h2>{{ store.policyLanguages[store.lang]['policy-21'] || 'Cookies'}}</h2>
      <p>{{ store.policyLanguages[store.lang]['policy-22'] || 'GoGoJumpVPN uses a few types of different cookies to improve the user experience on the website, such as:'}}</p>
      <ul>
        <li>{{ store.policyLanguages[store.lang]['policy-23'] || 'Google Analytics for statistical assessment and website performance improvement;'}}</li>
        <li>{{ store.policyLanguages[store.lang]['policy-24'] || 'Affiliate cookies to identify the customers referred to the Site by our partners, so that we can grant the referrers with commissions;'}}</li>
        <li>{{ store.policyLanguages[store.lang]['policy-25'] || 'Cookies for personalizing the content of the Site for users, such as setting the default language.'}}</li>
      </ul>

      <p>{{ store.policyLanguages[store.lang]['policy-26'] || 'You can set up warnings for every time the Site places a cookie in your browser, or you can choose to disable all cookies. You can do both through your browser settings. Since each browser has a different procedure for managing cookies, look at your browser’s Help Menu to learn the correct way to do it.'}}</p>

      <p>{{ store.policyLanguages[store.lang]['policy-27'] || 'Alternatively, you can disable all cookies by visiting the Network Advertising Initiative Opt Out page or by using the Google Analytics Opt Out Browser add-on. Please note that choosing to disable cookies may negatively affect some of the features that make your Site experience more efficient.'}}</p>

    </div>
    <div>
      <h2>{{ store.policyLanguages[store.lang]['policy-28'] || 'Third-Party Websites'}}</h2>
      <p>{{ store.policyLanguages[store.lang]['policy-29'] || 'The Site may contain links to external websites that do not fall under GoGoJumpVPN’s domain. GoGoJumpVPN is not responsible for the privacy practices or content of such external websites.'}}</p>
    </div>
    <div>
      <h2>{{ store.policyLanguages[store.lang]['policy-30'] || 'Consent and Age Restrictions'}}</h2>
      <p>{{ store.policyLanguages[store.lang]['policy-31'] || 'By using the Site, Content, Apps, Software, or Services, you agree to have your information handled as described in our Terms of Service and Privacy Policy.'}}</p>

      <p>{{ store.policyLanguages[store.lang]['policy-32'] || 'The Services are intended for adults aged 18 and above. If you believe your child has provided information to us, please let us know immediately.'}}</p>

    </div>
    <div>
      <h2>{{ store.policyLanguages[store.lang]['policy-33'] || 'Jurisdiction and Applicable Law'}}</h2>
      <p>{{ store.policyLanguages[store.lang]['policy-34'] || "Keeping your information private is our core mission. In service of this mission, GoGoJumpVPN's registered place of business is in Singapore, where has integrated data protection legislation, GoGoJumpVPN complies with the laws or the requirements of law enforcement agencies of the server’s region."}}</p>

      <p>{{ store.policyLanguages[store.lang]['policy-35'] ||  "Additionally, we will notify affected users about any requests for their account information, unless prohibited from doing so by law or court order."}}</p>
    </div>
    <div>
      <h2>{{ store.policyLanguages[store.lang]['policy-36'] || "Users in the European Union"}}</h2>

      <p>{{ store.policyLanguages[store.lang]['policy-37'] || "GoGoJumpVPN is committed to user privacy globally, and our existing practices reflect that through minimal collection of data and ensuring users have control over their personal information. The General Data Protection Regulation (GDPR) of the European Union (EU) requires us to outline those practices in a specific manner for users in the EU."}}</p>

      <p>{{ store.policyLanguages[store.lang]['policy-38'] || "In line with the GDPR, we collect and process the data outlined in this Privacy Policy on one of the following bases, depending on the circumstances:"}}</p>
      <ul>
        <li>{{ store.policyLanguages[store.lang]['policy-39'] || "For the purposes of fulfilling our contractual obligations to users, including:"}}</li>
        <li class="secrecy-li">{{ store.policyLanguages[store.lang]['policy-40'] || "Providing users with the Services and Apps they have requested.。"}}</li>
        <li class="secrecy-li">{{ store.policyLanguages[store.lang]['policy-41'] || "Managing user subscriptions and processing payments."}}</li>
        <li class="secrecy-li">{{ store.policyLanguages[store.lang]['policy-42'] || "Providing customer support."}}</li>
        <li>{{ store.policyLanguages[store.lang]['policy-43'] || "For a legitimate interest associated with the operation of our business, including:"}}</li>
        <li class=" secrecy-li">{{ store.policyLanguages[store.lang]['policy-44'] || "Enhancing the quality, reliability, and effectiveness of our Site, Services, and Apps."}}</li>
        <li class=" secrecy-li">{{ store.policyLanguages[store.lang]['policy-45'] || "Communicating with customers to provide information and seek feedback related to our Services and Apps."}}</li>
        <li>{{ store.policyLanguages[store.lang]['policy-46'] || "With the consent of users, which users can withdraw at any time."}}</li>
      </ul>

      <p>{{ store.policyLanguages[store.lang]['policy-47'] || "You can exercise your rights under the GDPR to access, transfer, correct, delete, or object to the processing of your personal information by contacting us at 7x24@cyberheart.io"}}</p>

    </div>
    <div>
      <h2>{{ store.policyLanguages[store.lang]['policy-48'] || "Changes to the Privacy Policy"}}</h2>
      <p>{{ store.policyLanguages[store.lang]['policy-49'] || "We may change our Privacy Policy from time to time, without prior notice to you, consistent with applicable privacy laws and principles. Your continued use of the Site or Services constitutes your acceptance of our Privacy Policy."}}</p>
    </div>
    <div>
      <h2>{{ store.policyLanguages[store.lang]['policy-50'] || "How to Contact US"}}</h2>
      <p>{{ store.policyLanguages[store.lang]['policy-51'] || "If you have any questions regarding our Privacy Policy and how we handle your information, please feel free to contact us at the following email address: 7x24@cyberheart.io"}}</p>
    </div>
    <div></div>
  </div>
  <Footer></Footer>
</template>


<script>
  import { useStore } from "vuex"

  import HeadUp from '../../components/Head.vue'
  import Footer from '../../components/Footer.vue'

  import utils from '../../utils/utils'
  import {getDomain} from "@/config";
  export default {
    name: 'PrivacyPolicy',
    components: {
      Footer,
      HeadUp
    },
    setup () {
      let useStores = useStore()
      let store = useStores.state

      function setTitle (lang) {
        document.title = store.languages[lang]['title']
      }
      function getTitle () {
        this.$http.get(`${getDomain('oc')}/api/website/vue/lpt-title`)
          .then(data => {
            const _data = data.data.obj
            Object.keys(_data).forEach(k => {
              store.languages[k]['title'] = _data[k]
            })
            setTitle(store.lang)
          })
          .catch(err => {
            console.log(err)
          })
      }

      function toHome(){
        window.location.href = `${utils.getQuery('from') || 'index'}.html`
      }

      function change (value) {
        window.location.href = `privacy_policy_${value}.html?from=${utils.getQuery('from') || 'index'}`
      }

      return {
        store,
        getTitle,
        setTitle,
        toHome,
        change,
      }
    },
    created () {
      this.getTitle()
      this.setTitle(this.store.lang)
      let html = document.getElementsByTagName('html')[0]
      utils.bindBGCEvent(html, 'white', '#000000')

      utils.creatCutomerService()
    }
  }
</script>

<style>
  * {
    padding: 0;
    margin: 0;
  }

  body {
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
  }
  body, html {
    font-size: 12px;
  }

  ul {
    list-style: none;
  }
  .trumpet {
    position: absolute;
    top: 12.4rem;
    display: inline-block;
    margin-left: -2.5rem;
    /*width: 2.67rem;*/
    /*height: 5.625rem;*/
    width: 135px;
    height: 64px;
  }

  .trumpet span {
    display: inline-block;
    line-height: 20px;
    font-weight: bold;
    width: 135px;
    height: 64px;
    text-align: center;
  }

  .trumpet p {
    transform: rotate(-6deg);
    -ms-transform: rotate(-6deg); /* Internet Explorer */
    -moz-transform: rotate(-6deg); /* Firefox */
    -webkit-transform: rotate(-6deg); /* Safari 和 Chrome */
    -o-transform: rotate(-6deg); /* Opera */
    margin-top: 13px;
    margin-left: 1rem;
    font-size: 1.3rem

  }
  .terms, .secrecy {
    background-color: white;
    padding: 1rem 3rem;
    font-family: "Panda Font", Arial, "Microsoft Yahei", "Microsoft Sans Serif", "WenQuanYi Micro Hei", sans-serif;
  }

  .terms p, .secrecy p {
    padding: 1rem 0 0 0;
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 3rem;
    color: rgb(86, 86, 86);
  }

  .terms h2, .secrecy h2 {
    padding: 1.5rem 0 0 0;
    line-height: 4rem;
    font-size: 2.4rem;
    font-weight: 800;
  }

  .terms li, .secrecy li {
    list-style: disc;
    margin-left: 3.5rem;
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 3rem;
    color: rgb(86, 86, 86);
  }

  .secrecy .secrecy-li {
    list-style: circle;
    margin-left: 6rem;
  }

  .prohibited p:nth-child(n+ 4):not(.notleft) {
    padding-left: 2.5rem;
  }

</style>

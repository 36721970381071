import App from "./App.vue";
import { createApp } from "vue";
import router from "./router/index";
import VueAxios from 'vue-axios'
import axios from 'axios'

import store  from "./store/stroe";

import utils from "./utils/utils"

utils.statsOpen()

createApp(App).use(VueAxios, axios).use(router).use(store).mount("#app");

<template>
  <div class="views">
    <div class="viewport-background" :class="isFixed && !isBanner.value ? 'background-fixed' : 'background-static'"></div>
    <div class="viewport-float" :class="isFixed && !isBanner.value ? 'float-fixed' : 'float-static'">
      <router-view />
      <div v-show="['', '/', 'banner'].includes(route.name) && isPro" class="pro">
        <Offer></Offer>
        <ChooseUs></ChooseUs>
        <Comment></Comment>
        <AutoDownload></AutoDownload>
      </div>
    </div>
  </div>
</template>

<script>
  import { reactive } from 'vue'
  import { useRoute } from 'vue-router'
  import AutoDownload from './AutoDownload.vue'
  import Comment from './Comment.vue'
  import ChooseUs from './ChooseUs.vue'
  import Offer from "./Offer"
  export default {
    name: 'CenterParcel',
    components: {
      Offer,
      Comment,
      ChooseUs,
      AutoDownload,
    },
    props:{
      isFixed: {
        type: Boolean,
        default: false
      },
      isPro: {
        type: Boolean,
        default: false
      }
    },
    setup () {
      let route = useRoute()

      let isBanner = reactive({value:['', '/', 'banner'].includes(route.name)})
      let viewportBackgroundStyleFixed = {
        height: '100%',
        display: 'block',
        position: 'fixed',
        width: '100%'
      }
      let viewportBackgroundStyleStatic = {
        float: 'none',
        'background-image': "url('~@/assets/images/sky.png')"
      }
      let viewportFloatStyleFixed = {
        'background-image': 'none',
        'background-color': 'transparent'
      }
      let viewportFloatStyleStatic = {
        'background-image': 'url(@/assets/images/sky.png)',
        'background-color': 'transparent',
        float: 'none'
      }

      return {
        route,
        isBanner,
        viewportFloatStyleFixed,
        viewportFloatStyleStatic,
        viewportBackgroundStyleFixed,
        viewportBackgroundStyleStatic,
      }
    },
  }
</script>

<style>

  .intro {
    height: 100%;
    box-sizing: border-box;
    font-size: 1.2rem;
    text-align: center;
    padding-top: 6rem;
    padding-bottom: 2rem;

  }
  .views {
    height: 100%;
    box-sizing: border-box;
    background-color: #a1eeff;
  }
  .pro {
    background-color: #FFFFFF;
  }

  .intro span {
    display: inline-block;
    vertical-align: middle;
  }

  .intro ul {
    text-align: center;
  }

  .intro .step-title {
    background-color: #CC1424;
    color: white;
    width: 70%;
    max-width: 500px;
    margin: 0 auto 1.2rem auto;
    padding: 0.25rem;
    font-size: 1.5rem;
    border: 3px solid #4D2C22;
    border-radius: 10rem;
    box-shadow: 0 4px 0 0 #00000030;
    -webkit-box-shadow: 0 4px 0 0 #00000030;
    -moz-box-shadow: 0 4px 0 0 #00000030;
  }

  .intro .step-box {
    display: block;
    background-color: #EEEEEE;
    color: black;
    width: 86%;
    max-width: 500px;
    margin: 0 auto 1.2rem auto;
    padding: 1.5rem 8px;
    font-size: 1.2rem;
    border: 3px solid #4D4D4D;
    border-radius: 2rem;
    box-shadow: inset 0 4px 0 0 white, 0 4px 0 0 #00000030;
    -webkit-box-shadow: inset 0 4px 0 0 white, 0 4px 0 0 #00000030;
    -moz-box-shadow: inset 0 4px 0 0 white, 0 4px 0 0 #00000030;
  }

  .intro .step-box hr {
    margin: 1.5rem;
    border: 0;
    border-top: 2px dotted lightgrey;
  }

  .intro .step-comment {
    font-size: 1rem;
    color: darkgrey;
  }

  .intro .app-store-like .app-icon {
    height: 6rem;
    margin-right: .5rem;
    border-radius: 1.4rem;
    display: block;
  }

  .intro .app-store-like ul {
    text-align: left;
  }

  .intro .app-store-like .app-title {
    color: black;
    font-size: 1.5rem;
  }

  .intro .app-store-like .app-intro {
    color: #999999;
    margin-bottom: 0.2rem;
    font-size: 1rem;
  }

  .intro .app-store-like .app-get {
    background-color: #0066FF;
    border-radius: 20rem;
    border: none;
    color: white;
    padding: 0.2rem 1.2rem;
    display: inline-block;
    font-size: 1.2rem;
    cursor: pointer;
    text-decoration: none;
    min-width: 3.5rem;
    text-align: center;
  }
  .intro .step-number {
    background-color: #000;
    color: #fff;
    border-radius: 10rem;
    border: none;
    margin: auto auto 1.2rem auto;
    width: 2.2rem;
    height: 2.2rem;
    line-height: 2.2rem;
    font-size: 1.2rem;
    text-align: center;
  }
  .intro .app-store-like .app-get.unpaid {
    background-color: #b2b2b2;
  }

  .views .background-fixed {
    display: block;
    position: absolute;
  }
  .views .background-static {
    float: none;
    background-image: url('~@/assets/images/sky.png')

  }
  .viewport-background {
    background: url('~@/assets/images/sky.png') no-repeat right bottom;
    background-size: 50% auto;
    z-index: -2;
  }
  .views .float-fixed{
    /*background-image: none;*/
    /*background-color: transparent;*/
    background: url('~@/assets/images/sky.png') no-repeat right bottom;
    background-size: 50% auto;
    height: 100%;
  }
  .views .float-static{
    background-color: transparent;
    background: url('~@/assets/images/sky.png') no-repeat right bottom;
    float: none;
    background-size: 50% auto;
  }
  .viewport-float {
    height: 100%;
    width: 100%;
    justify-content: center;
  }
</style>

<template>
  <div class="open-in-browser" :style="{display: display}"><img :src="imgUrl"/></div>
  <div class="open-in-browser overlay" :style="{display: display}"></div>
</template>

<script>
  export default {
    name: 'Access',
    components: {},
    props: {},
    data () {
      return {
        display: 'none',
        imgUrl: ''
      }
    },
    computed: {},
    watch: {},
    created () {
      this.checkBrowser()
    },
    methods: {
      checkBrowser () {
        var ua = navigator.userAgent.toLowerCase();
        if (ua.match(/MicroMessenger/i) != 'micromessenger'
          && ua.match(/WeiBo/i) != 'weibo'
          && ua.match(/QQTheme/i) != 'qqtheme') {
          return
        }
        this.display = 'block'
        if (ua.indexOf('android') > -1) {
          this.imgUrl = require('@/assets/images/open_in_browser.png')
        } else if (ua.match(/\(i[^;]+;( u;)? cpu.+mac os x/)) {
          this.imgUrl = require('@/assets/images/open_in_safari.png')
        } else {
          return
        }
        let elements = document.querySelectorAll('.open-in-browser')
        elements.forEach(element => {
          element.addEventListener('touchstart', event => {
            event.preventDefault()
          }, false)
        })
      }
    }
  }
</script>

<style scoped>
  .open-in-browser img {
    position: absolute;
    right: 0;
    top: 0;
    width: 75%;
    z-index: 1000;
  }

  .overlay {
    background-color: black;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    filter: alpha(Opacity=75);
    -moz-opacity: 0.75;
    opacity: 0.75;
    z-index: 100;
  }
</style>

import languages from "../common/languages"
import { createStore } from 'vuex'

let domain = process.env.VUE_APP_HOST_OC
// Create a new store instance.
const store = createStore({
  state () {
    return {
      lang: 'zh',
      languages,
      footerHeight: 0,
      headHeight: 0,
      domain,
      env: window.env,
      toIosAppStoreFaq: false,
      toIosTestflightFaq: false,
      downloads: null
    }
  },
  mutations: {
    change (state, currentLang) {
      state.lang = currentLang
    },
    setDomain(state, domain) {
      state.domain = domain
    },
    changeToPath(state, data) {
      state[data['tag']]= data['value']
    },
    setHeight (state, typevalue) {
      state[typevalue[0]] = typevalue[1]
    },
    setDownloads(stat, v) {
      stat['downloads'] = v
    } 
  }
})
export default  store

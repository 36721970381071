import { HUAWEI, XIAOMI }from "@/common/PHONE_MODEL";
const _attrName = 'div-customer'
const _attrValue = 'customer-service'
import axios from 'axios'
import {getDomain} from "@/config";

import {traceInfo} from '../hooks/useTrace'


function genGuid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r&0x3 | 0x8);
    return v.toString(16);
  });
}

function getUserID() {
  let user_id = window.localStorage.getItem('GOJ_USER_ID')
  if (!user_id) {
    let temp_user_id = genGuid()
    window.localStorage.setItem('GOJ_USER_ID', temp_user_id)
    user_id = temp_user_id
  }
  return user_id
}

function getOSName(){
  let isAndroid = window.navigator.userAgent.toLowerCase().indexOf('android') > -1;
  let isIos = (window.navigator.userAgent.toLowerCase().indexOf('iphone') > -1);
  let isIpad = /(iPad|iPod|iOS)/i.test(navigator.userAgent) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
  let isMac =  /macintosh|mac os x/i.test(navigator.userAgent);
  let isWindows = /windows|win32/i.test(navigator.userAgent);
  if (isAndroid) return 'android'
  if (isIos) return 'ios'
  if (isIpad) return 'ios'
  if (isMac) return 'macos'
  if (isWindows) return 'windows'
  return 'other'
}


let userInfo = `os=${getOSName()}`

axios.interceptors.request.use(
  config => {
    config.url = config.url.includes("?") ? config.url + `&${userInfo}` : `${config.url}?${userInfo}`
    return config;
  },
  error => {
    return Promise.reject(error)
  }
)


function getScriptByString (htmlString) {
  let scriptPool = []
  const reg = /<script.*?>(.|[\r\n])*?<\/script>/g
  htmlString.match(reg).forEach(e => {
    scriptPool.push(e)
  })
  return scriptPool
}

function insertLastScriptNode (script) {
  let scriptDiv = document.createElement('div')
  scriptDiv.innerHTML = script
  scriptDiv.setAttribute(_attrName, 'customer-service')
  document.body.insertBefore(scriptDiv, document.body.lastChild);
}

function getLastScriptNode () {
  let scripts = document.getElementsByTagName('script');
  let r = null
  Array.from(scripts).forEach(s => {
    if (s.parentNode.getAttribute(_attrName) === _attrValue) r = s
  })
  return r || scripts[scripts.length - 1];
}

function setAttributeByScript (element, scriptElement) {
  if (!element) return
  for (let i = 0; i < element.attributes.length; i++) {
    if (element.attributes[i].specified) {
      scriptElement.setAttribute(element.attributes[i].name, element.attributes[i].value)
    }
  }
}

// 滑动背景色
let touchPosition = window.screen.height / 5
let scroll = 0
const utils = {
  parseFuncs () {
    var clickElement = document.getElementById('clickElement')
    // var inproc = false
    var funcs = []
    for (var i = 0; i < clickElement.childNodes.length; i++) {
      var node = clickElement.childNodes[i]
      var script = node.textContent
      var m = /function\s+(\w+)\s*\(/.exec(script)
      if (!m) continue
      funcs.push(m[1])
    }
    return funcs
  },
  initClickFunctions () {
    let funcs = utils.parseFuncs()
    let btns = document.querySelectorAll('.download-button')
    btns.forEach(btn => {
      var url = btn.href
      let fn = function () {
        if (!funcs.includes('install')) return true
        return window['install'](url, btn.className.split(' '));
      }
      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) {
        btn.addEventListener('touchend', fn)
      } else {
        btn.onclick = fn
      }
    })
  },
  sbc (dom, topColor, bottomColor) {
    dom.style.backgroundColor = (document.documentElement.scrollTop || document.body.scrollTop) <= touchPosition ? topColor : bottomColor
  },
  bindBGCEvent (dom, topColor, bottomColor) {
    utils.sbc(dom, topColor, bottomColor)
    window.onscroll = function () {
      let tem = (document.documentElement.scrollTop || document.body.scrollTop)
      dom.style.backgroundColor = tem - scroll <= 0 ? topColor : bottomColor
    }
    dom.addEventListener('touchstart', function () {
      utils.sbc(dom, topColor, bottomColor)
    })
  },
  setCookie (cname, cvalue, exdays = 360) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + "; " + expires;
  },
  getCookie (cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1);
      if (c.indexOf(name) != -1) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  },
  clearCookie (cname) {
    var d = new Date();
    d.setTime(-1);
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=''; " + expires;
  },
  download (href) {
    const a = document.createElement('a');
    a.setAttribute('href', href);
    a.click()
  },
  copy(value) {
    if (navigator && "clipboard" in navigator) {
      navigator.clipboard.writeText(value).catch(e=> {
        console.log(e);
        this.legacyCopy(value);
      });
    } else {
      this.legacyCopy(value);
    }
  },
  legacyCopy(value) {
    const ta = document.createElement("textarea");
    ta.value = value != null ? value : "";
    ta.style.position = "absolute";
    ta.style.opacity = "0";
    document.body.appendChild(ta);
    ta.select();
    document.execCommand("copy");
    ta.remove();
  },
  // copy (value) {
    // var currentFocus = document.activeElement;

    // var input = document.createElement('input');
    // document.body.appendChild(input);
    // input.style.opacity = 0;
    // input.value = value;
    // var scrollY = window.scrollY;

    // input.focus();
    // input.setSelectionRange(0, input.value.length);

    // var res = document.execCommand('copy', true);

    // currentFocus.focus();
    // document.body.removeChild(input);

    // window.scrollTo(0, scrollY);

    // return res;
  // },

  getQuery (variable) {
    let query = window.location.search.substring(1);
    let pars = []
    if (query) {
      pars = query.split("&");
    } else if (window.location.hash.includes('?')) {
      let hash = window.location.hash
      pars = hash.split("?")[1].split("&");
    }
    for (let i = 0; i < pars.length; i++) {
      let pair = pars[i].split("=");
      if (pair[0] === variable) {
        return pair[1];
      }
    }
    return false;
  },

  creatCutomerService () {
    axios.get(`${process.env.VUE_APP_HOST_OC}/api/website/vue/customer-service`)
      .then((response) => {
        let script = response.data['obj']['customer-service'].toString()
        let pool = getScriptByString(script)
        pool.forEach(item => {
          insertLastScriptNode(item)
          let lastScriptNode = getLastScriptNode()
          let scriptElement = document.createElement('script')
          setAttributeByScript(lastScriptNode, scriptElement)
          document.body.insertBefore(scriptElement, document.body.firstChild);
          eval(lastScriptNode.innerText)
        })
      }).catch(e => {
      console.log(e)
    })
  },

  bindSwiperEvent (dom, leftCallBack, rightCallback) {
    let isMove = false;
    let startX = 0;
    let distanceX = 0;
    dom.addEventListener('touchstart', function (e) {
      startX = e.touches[0].clientX;
    })

    dom.addEventListener('touchmove', function (e) {
      isMove = true;
      let moveX = e.touches[0].clientX;
      distanceX = moveX - startX;
    })

    dom.addEventListener('touchend', function (e) {
      if (isMove && Math.abs(distanceX) >= 3) {
        if (distanceX > 0) {
          rightCallback && rightCallback.call(this, e);
        } else {
          leftCallBack && leftCallBack.call(this, e);
        }
      }
    })
  },
  getOSName,
  getOSIsXM() {
    let res = false;
    for (let i = 0; i < XIAOMI.length; i++) {
      if (new RegExp(XIAOMI[i].replaceAll(" ", "").toLowerCase()).test(navigator.userAgent.replaceAll(" ", "").toLowerCase())) {
        return true;
      }
    }
    return res || /(xiaomi|redmi)/.test(window.navigator.userAgent.replaceAll(" ", "").toLowerCase());
  },
  getIsHW() {
    let res = false;
    for (let i = 0; i < HUAWEI.length; i++) {
      if (new RegExp(HUAWEI[i].replaceAll(" ", "").toLowerCase()).test(navigator.userAgent.replaceAll(" ", "").toLowerCase())) {
        return true;
      }
    }
    return res || /(harmonyos|hauwei|honor)/i.test(navigator.userAgent.toLowerCase());
  },
  getIsHM() {
    return /(harmonyos)/i.test(navigator.userAgent.toLowerCase());
  },
  getIsSafari(){
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  },

  genGuid: genGuid,
  getUserID: getUserID,
  sendStatsSync(type, path){
    let user_id = this.getUserID()
    let from = this.getQuery('from')
    if (from === 'index') from ='official'
    if (window.env && window.env.channel) from = window.env.channel

    const url = `${getDomain('tdc')}/api/${path}/${type}?os=${type}`

    const data = {
      "user_id": user_id,
      "channel": from || 'official'
    }

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data),
      keepalive: true
    }).then();
  },
  sendStats(type, path){
    let user_id = this.getUserID()
    let from = this.getQuery('from')
    if (from === 'index') from ='official'
    if (window.env && window.env.channel) from = window.env.channel
    try {
      axios.post(`${getDomain('tdc')}/api/${path}/${type}`,
        {
              "user_id": user_id,
              "channel": from || 'official'
            },

      ).then(() => {

      }).catch((e) => {
        console.log(e)
      })
    } catch (e) {
      console.log(e)
    }
  },
  statsDownload (type) {
    this.sendStats(type, 'incuc')
  },
  statsDownloadSync (type) {
    this.sendStatsSync(type, 'incuc')
  },
  statsOpen () {
    this.sendStats(this.getOSName(), 'incpv')
  },
  getDownloads(ch = null, pkg = null) {
    const params = {
      bid: this.getUserID()
    }
    if (ch) {
      params.ch = ch
    }
    if (pkg) {
      params.pkg = pkg
    }
    return axios.get(`${getDomain('oc')}/api/cpkg`, {
      params: params
    }).catch(e => {
      console.log(e)
    })
  },
  getDeviceWebGLInfo() {
    var e = document.createElement("canvas");
    if (e && "function" == typeof e.getContext) {
      for (var t = ["webgl", "webgl2", "experimental-webgl2", "experimental-webgl"], n = 0; n < t.length; n++) {
        var r = t[n]
          , o = e.getContext(r);
        if (o) {
          var i = {};
          // i.context = r,
          // i.version = o.getParameter(o.VERSION),
          i.glv = o.getParameter(o.VENDOR);
          // i.sl_version = o.getParameter(o.SHADING_LANGUAGE_VERSION),
          // i.max_texture_size = o.getParameter(o.MAX_TEXTURE_SIZE);
          let a = o.getExtension("WEBGL_debug_renderer_info");
          if (a) {
            i.glv = o.getParameter(a.UNMASKED_VENDOR_WEBGL);
            i.glr = o.getParameter(a.UNMASKED_RENDERER_WEBGL);
          }
          return i
        }
      }
    }
    return {          
      glr: "",
      glv: ""
    }
  },
  getDevicePixelRatio(){
    return { ss: window.devicePixelRatio }
  },
  getDeviceSize(){
    return {
      sw: window.screen.width,
      sh: window.screen.height,
    }
  },
  callLoaclTRC(e){
    try {
      var n = window.RTCPeerConnection || window.webkitRTCPeerConnection || window.mozRTCPeerConnection;
      if (n) {
        var r = new n({
          iceServers: []
        });
        r.createDataChannel("tracecat", {
          reliable: !1
        }),
        r.onicecandidate = function(n) {
          if (n && n.candidate) {
            var r = n.candidate.candidate;
            const s = r.split(" ")[4];
            e(s)
          }
        },
        r.createOffer(function(e) {
          r.setLocalDescription(e)
        }, function(e) {})
      }
    } catch(err){
      window.setTimeout(function (){
        e()
      }, 300)
    }
  },
  getBid() {
    return {
      bid: getUserID(),
      key: "tracat"
    }
  },
  getPr() {
    const r = this.getQuery('referrer')
    return {
      channel: window.env.channel,
      referrer: r ? r : '',
    }
  },
  onTrace() {
    let r = Object.assign(
      {},
      this.getBid(),
      this.getDeviceSize(),
      this.getDevicePixelRatio(),
      this.getDeviceWebGLInfo(),
      )
    let pr = this.getPr();

    let o = Object.assign({}, r, pr);
    let url = `${getDomain('oc')}/api/tracecat?bid=${r.bid}&key=${r.key}&sw=${r.sw}&sh=${r.sh}&ss=${r.ss}&glr=${r.glr}&glv=${r.glv}&pr=${JSON.stringify(pr)}`;
    traceInfo.value = JSON.stringify(o);
    if (this.getIsSafari()) {
      axios.post(url).then((res) => {
        traceInfo.value = JSON.stringify(res.data)
      })
      .catch(err => {
        console.log(err)
        axios.post(url).then((res) => {
          traceInfo.value = JSON.stringify(res.data)
        })
      })
          
    } else {
      this.callLoaclTRC((e) =>  {
        if (e && !o.li) {
          o.li = e;
          url += `&li=${e}`;
        }
        traceInfo.value = JSON.stringify(o);
        axios.post(url)
        .then((res) => {
          traceInfo.value = JSON.stringify(res.data)
        })
        .catch(err => {
          console.log(err)
          axios.post(url).then((res) => {
            traceInfo.value = JSON.stringify(res.data)
          })
        })
      })
    };
  },

}
export default utils;

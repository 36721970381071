<template>
  <HeadUp :toHome="toHome" :change="change"></HeadUp>
  <div class=" terms viewport">
    <div style="height: 5.58rem" name="downloadAPP"></div>
    <div>
      <h2>{{ store.termsLanguages[store.lang]['terms-1'] || "服务条款1"}}</h2>
      <p>{{ store.termsLanguages[store.lang]['terms-2'] || "狗急加速VPN 有权在任何时间及不需事先通知的情况下修改、取消、增加、替代任何在此列明的条款，而此条款均对本网站的使用者及注册会员有效力及约束力。因此，您应定期浏览本页。如在本条款作出修订后仍继续使用本网站，即被视为接受这些修订。"}}</p>
      <p>{{ store.termsLanguages[store.lang]['terms-3'] || "当您使用 狗急加速VPN 或注册成为本平台会员的同时，您将会被视作接受本条款之所有规则约束。请您在使用本平台前仔细阅读所有有关条款。"}}</p>
      <p>{{ store.termsLanguages[store.lang]['terms-4'] || "本条款最新修订日期为二零二四年六月六日"}}</p>
    </div>
    <div>
      <h2>{{ store.termsLanguages[store.lang]['terms-5'] || "服务"}}</h2>
      <p>{{ store.termsLanguages[store.lang]['terms-6'] || "狗急加速VPN 服务是暂时性的数字网络通信服务，可增强 Internet 安全性和隐私性。根据您的个人要求和您的全权酌情决定权，我们会自动提供不受监管和不受监控的数据管道-虚拟专用网（VPN）连接-供您专有使用，您对使用 狗急加速VPN 服务负全责。狗急加速VPN 对用户采取的行动不承担任何责任，包括对已执行或未执行的损害承担刑事责任和民事责任。这些条款的其他规定进一步限制了 狗急加速VPN 的责任。"}}</p>
      <p>{{ store.termsLanguages[store.lang]['terms-7'] || "我们授予您使用 狗急加速VPN 服务的许可，但要遵守这些条款中规定的限制。您使 狗急加速VPN 服务的风险自负。狗急加速VPN 服务可以随时修改，更新，中断或暂停，恕不另行通知或承担责任。对于由此造成的对您的任何伤害或其他不利后果，我们不承担任何责任。狗急加速VPN，其所有者，员工，代理商和其他与 狗急加速VPN 服务相关的人员，对于因使用或向使用其注册的任何账户而产生或引起的任何已执行或未执行的任何形式的损害，概不负责。"}}</p>
      <p>{{ store.termsLanguages[store.lang]['terms-8'] || "狗急加速VPN 保证99.9％的服务正常运行时间。在任何情况下，狗急加速VPN 对超出客户向 狗急加速VPN 支付的任何损失，金钱或非金钱损失概不负责，并且不包括律师费或法院费用，与任何法律或其他成文法则无关。"}}</p>
    </div>
    <div>
      <h2>{{ store.termsLanguages[store.lang]['terms-9'] || "知识产权"}}</h2>
      <p>{{ store.termsLanguages[store.lang]['terms-10'] || "根据这些条款和条件，我们授予您有限的、非排他的、个人的、不可转让的、不可再许可的许可，以 :"}}</P>
      <ul>
        <li class="terms-li">{{ store.termsLanguages[store.lang]['terms-11'] || "下载和使用 狗急加速VPN 软件的副本；"}}</li>
        <li class="terms-li">{{ store.termsLanguages[store.lang]['terms-12'] || "使用 狗急加速VPN 服务，包括但不限于在 狗急加速VPN 软件或我们的网站上或通过我们的网站提供的产品和服务。根据 狗急加速VPN 服务，本协议未授予您任何其他权利或许可。本文提供的许可在终止之前一直有效。如果您不遵守这些条款，该许可将自动终止。"}}</li>
      </ul>
      <p>
        {{ store.termsLanguages[store.lang]['terms-13'] || "狗急加速VPN 服务（包括但不限于 狗急加速VPN 软件，移动应用程序和所有其他项目）由 狗急加速VPN 拥有并拥有版权，并且在全球范围内受到保护。我们保留对 狗急加速VPN 服务及其任何部分的权利，所有权和利益，包括但不限于所有版权，商标，服务标记，商业秘密和其他知识产权。您不得采取任何行动来危害，限制或以任何方式干扰我们对 狗急加速VPN 服务的所有权和权利。除非另有说明，否则保留所有权利。"}}
      </p>
      <div class="prohibited">
        <h2>{{ store.termsLanguages[store.lang]['terms-14'] || "禁止事项"}}</h2>
        <p>{{ store.termsLanguages[store.lang]['terms-15'] || "您对 狗急加速VPN 服务的访问和使用受这些条款以及所有适用法律和法规的约束。对于任何违反任何适用法律或本条款的用户，我们保留随时酌情决定随时终止或阻止其访问 狗急加速VPN 服务的权利，无论是否另行通知。"}}</p>

        <p>{{ store.termsLanguages[store.lang]['terms-16'] || "在使用 狗急加速VPN 服务时，您自己同意不协助、鼓励或允许其他人使用 狗急加速VPN 服务："}}</p>
        <p>{{ store.termsLanguages[store.lang]['terms-17'] || "1) 用于不正当的、禁止的、违法、犯罪或者欺诈活动，包括但不限于端口扫描，发送垃圾邮件，发送选择接收的电子邮件，扫描开放中继或开放代理，发送未经请求的电子邮件或发送的任何版本或类型的电子邮件即使通过第三方服务器路由电子邮件，任何弹出窗口启动，使用被盗信用卡，信用卡欺诈，金融欺诈，加密货币欺诈，伪装，敲诈，勒索，绑架，强奸，谋杀，出售的信用卡被盗，出售被盗商品，提供或出售违禁，军事和双重用途商品，提供或出售受控物质，身份盗窃，黑客入侵，伪造，网络钓鱼，任何形式或规模的刮取，数字盗版，知识产权侵权和其他类似活动；"}}</p>
        <p>{{ store.termsLanguages[store.lang]['terms-18'] || "2) 通过 狗急加速VPN 服务以任何方式或形式攻击，干扰，获得未经授权的访问，拒绝任何其他网络，计算机或节点的服务；"}}</p>
        <p>{{ store.termsLanguages[store.lang]['terms-19'] || "3) 以任何方式剥削儿童，包括音频，视频，摄影，数字内容等；"}}</p>
        <p>{{ store.termsLanguages[store.lang]['terms-20'] || "4) 以任何方式上传或分发包含病毒，蠕虫，特洛伊木马，损坏的文件或任何其他可能损害他人计算机操作的类似软件或程序的文件；"}}</p>
        <p>{{ store.termsLanguages[store.lang]['terms-21'] || "5) 干扰或试图干扰 狗急加速VPN 服务的正常工作，与 狗急加速VPN 服务有关的任何交易或我们进行的任何其他活动，破坏我们的网站或与 狗急加速VPN 服务相连的任何网络或绕过任何措施我们可能会用来阻止或限制对 狗急加速VPN 服务的访问；"}}</p>
        <p>{{ store.termsLanguages[store.lang]['terms-22'] || "6) 以任何未经授权的方式利用 狗急加速VPN 服务，包括但不限于侵入或增加网络容量；"}}</p>
        <p>{{ store.termsLanguages[store.lang]['terms-23'] || "7) 未经我们事先书面许可，出于任何目的使用任何机器人，蜘蛛，刮板或其他自动方式访问我们的网站或 狗急加速VPN 服务；"}}</p>
        <p>{{ store.termsLanguages[store.lang]['terms-24'] || "8) 收集或收集有关 狗急加速VPN 服务其他用户的个人信息；"}}</p>
        <p>{{ store.termsLanguages[store.lang]['terms-25'] || "9) 未经合法利益或同意，或违反任何适用法律而收集或收集个人数据；"}}</p>
        <p>{{ store.termsLanguages[store.lang]['terms-26'] || "10) 采取任何可能会对我们的基础架构施加不合理或不成比例的巨大负载的措施；"}}</p>
        <p>{{ store.termsLanguages[store.lang]['terms-27'] || "11) 与任何不合理数量的人共享任何数据或其他内容，包括但不限于向大量接收者发送爆炸性通信或与您不认识或不认识您的人共享内容；"}}</p>
        <p>{{ store.termsLanguages[store.lang]['terms-28'] || "12) 代表您是任何 狗急加速VPN 服务（包括其任何功能）的代表或代理商；"}}</p>
        <p>{{ store.termsLanguages[store.lang]['terms-29'] || "13) 将 狗急加速VPN 服务或其任何部分合并到任何其他程序或产品中；"}}</p>
        <p>{{ store.termsLanguages[store.lang]['terms-30'] || "14) 以任何形式或通过任何方式复制或复制 狗急加速VPN 服务的任何部分；"}}</p>
        <p>{{ store.termsLanguages[store.lang]['terms-31'] || "15) 根据 狗急加速VPN 服务修改，翻译，反向工程，反编译，反汇编或创建任何衍生作品，包括其任何文件或文档，或其任何部分，或确定或尝试确定任何源代码，算法，方法或 狗急加速VPN 应用程序或其任何部分中包含的技术；"}}</p>
        <p>{{ store.termsLanguages[store.lang]['terms-32'] || "16) 违反一般的道德或道德规范，良好风俗和公平行为规范；"}}</p>
        <p>{{ store.termsLanguages[store.lang]['terms-33'] || "17) 侵犯任何第三方的权利，包括任何违反保密性，个人数据，版权或任何其他知识产权或所有权的权利；"}}</p>
        <p>{{ store.termsLanguages[store.lang]['terms-34'] || "18) 威胁，跟踪，伤害或骚扰他人，或助长偏执或歧视；"}}</p>
        <p>{{ store.termsLanguages[store.lang]['terms-35'] || "19) 试图操纵 狗急加速VPN 名称，服务或产品；"}}</p>
        <p>{{ store.termsLanguages[store.lang]['terms-36'] || "20) 向未成年人征求或收集个人信息或与未成年人交流；"}}</p>
        <p>{{ store.termsLanguages[store.lang]['terms-37'] || "21) 试图通过黑客攻击，密码挖掘，暴力破解或任何其他方式未经授权访问 狗急加速VPN 服务，用户帐户，连接到 狗急加速VPN 服务的计算机系统或网络；"}}</p>
        <p>{{ store.termsLanguages[store.lang]['terms-38'] || "22) 将 狗急加速VPN 服务用于任何军事目的，包括网络战，武器开发，设计，制造或生产导弹，核，化学或生物武器；"}}</p>
        <p>{{ store.termsLanguages[store.lang]['terms-39'] || "23) 以其他方式侵犯或规避这些条款。"}}</p>
        <p class="notleft">{{ store.termsLanguages[store.lang]['terms-40'] || "除此之外，长时间占用宽带进行下载导致服务器网络异常，影响到其他用户正常使用的，狗急加速VPN 会采取措施限制滥用 IP 的网速。"}}</p>
        <p class="notleft">{{ store.termsLanguages[store.lang]['terms-41'] || "为了您的账号安全，非定制账号仅限个人使用，请勿将账号分享给他人，分享给他人造成的损失将由您自己承担。"}}</p>
        <p class="notleft">{{ store.termsLanguages[store.lang]['terms-42'] || "我们保留自行决定拒绝服务，暂停帐户或限制访问 狗急加速VPN 服务的权利。狗急加速VPN 可以立即实施此类暂停或访问限制，而无需任何指示，通知或退款。我们可能会暂停您的帐户以进行澄清，调查，或要求您解释您的操作并提供其他信息。如果您的帐户已被暂停，则必须与我们联系以获取更多信息。在我们永久终止用户帐户之前，我们可能会在合理的时间内暂停您的用户帐户。"}}</p>
        <p class="notleft">{{ store.termsLanguages[store.lang]['terms-43'] || "我们没有义务对您执行条款。我们鼓励您让我们知道任何 狗急加速VPN 用户违反这些条款的情况；但是，如果发生此类违规行为，我们可以自行决定采取适当的措施。"}}</p>
      </div>
      <div>
        <h2>{{ store.termsLanguages[store.lang]['terms-44'] || "免责声明"}}</h2>
        <p>
          {{ store.termsLanguages[store.lang]['terms-45'] || "我们将努力防止网站和服务受到干扰。但是，这些内容是按“原样”和“可用”的基础提供的，我们不保证（明示或暗示）通过本网站或服务提供的任何材料或信息的准确性，或它们对以下内容的适用性：任何特定目的。我们明确否认任何形式的担保，无论是明示的还是暗示的，包括但不限于适销性或针对特定目的的适用性或非侵权的担保。我们不保证本服务将满足您的要求，也不保证其不间断，及时，安全或无错误，也不对任何缺陷进行纠正。您承认自己承担风险和酌情权访问网站和服务。"}}</p>
        <p>{{ store.termsLanguages[store.lang]['terms-46'] || "狗急加速VPN 保留调查我们认为违反这些条款的问题的权利。我们可能会（但无义务）在不另行通知的情况下，自行决定，以任何方式删除，阻止，过滤或限制我们认为是实际或潜在违反本条款所规定限制的任何材料或信息，以及任何其他可能使 狗急加速VPN 或我们的客户承担责任的活动。狗急加速VPN 对我们未能防止此类材料或信息通过“服务”和/或进入您的计算设备的任何故障不承担任何责任。"}}</p>
      </div>
      <div><h2>{{ store.termsLanguages[store.lang]['terms-47'] || "适用法律"}}</h2>
        <p>{{ store.termsLanguages[store.lang]['terms-48'] || "本协议应受新加坡法律的管辖并根据其解释，但其法律冲突规则除外。"}}</p>
      </div>
      <div><h2>{{ store.termsLanguages[store.lang]['terms-49'] || "服务条款的变更"}}</h2>
        <p>{{ store.termsLanguages[store.lang]['terms-50'] || "根据适用的法律和原则，我们可能会不时更改条款服务条款，恕不另行通知。您继续使用本网站或服务即表示您接受我们的隐私政策。"}}</p>
      </div>
      <div><h2>{{ store.termsLanguages[store.lang]['terms-51'] || "联系我们"}}</h2>
        <p>{{ store.termsLanguages[store.lang]['terms-52'] || "如果您对我们的隐私政策以及我们如何处理您的信息有任何疑问，请随时通过以下电子邮件地址与我们联系：7x24@cyberheart.io"}}</p>
      </div>
      <div></div>
    </div>
  </div>
  <Footer></Footer>
</template>



<script>
  import { useStore } from "vuex"

  import HeadUp from '../../components/Head.vue'
  import Footer from '../../components/Footer.vue'

  import utils from '../../utils/utils'
  import {getDomain} from "@/config";
  export default {
    name: 'TermsOfService',
    components: {
      Footer,
      HeadUp
    },
    setup () {
      let useStores = useStore()
      let store = useStores.state

      function setTitle (lang) {
        document.title = store.languages[lang]['title']
      }
      function getTitle () {
        this.$http.get(`${getDomain('oc')}/api/website/vue/lpt-title`)
          .then(data => {
            const _data = data.data.obj
            Object.keys(_data).forEach(k => {
              store.languages[k]['title'] = _data[k]
            })
            setTitle(store.lang)
          })
          .catch(err => {
            console.log(err)
          })
      }

      function toHome(){
        window.location.href = `${utils.getQuery('from') || 'index'}.html`
      }

      function change (value) {
        window.location.href = `terms_of_service_${value}.html?from=${utils.getQuery('from') || 'index'}`
      }

      return {
        store,
        getTitle,
        setTitle,
        toHome,
        change,
      }
    },
    created () {
      this.getTitle()
      this.setTitle(this.store.lang)
      let html = document.getElementsByTagName('html')[0]
      utils.bindBGCEvent(html, 'white', '#000000')

      utils.creatCutomerService()
    }
  }
</script>

<style>
  * {
    padding: 0;
    margin: 0;
  }

  body {
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
  }
  body, html {
    font-size: 12px;
  }

  ul {
    list-style: none;
  }
  .trumpet {
    position: absolute;
    top: 12.4rem;
    display: inline-block;
    margin-left: -2.5rem;
    /*width: 2.67rem;*/
    /*height: 5.625rem;*/
    width: 135px;
    height: 64px;
  }

  .trumpet span {
    display: inline-block;
    line-height: 20px;
    font-weight: bold;
    width: 135px;
    height: 64px;
    text-align: center;
  }

  .trumpet p {
    transform: rotate(-6deg);
    -ms-transform: rotate(-6deg); /* Internet Explorer */
    -moz-transform: rotate(-6deg); /* Firefox */
    -webkit-transform: rotate(-6deg); /* Safari 和 Chrome */
    -o-transform: rotate(-6deg); /* Opera */
    margin-top: 13px;
    margin-left: 1rem;
    font-size: 1.3rem

  }
  .terms, .secrecy {
    background-color: white;
    padding: 1rem 3rem;
    font-family: "Panda Font", Arial, "Microsoft Yahei", "Microsoft Sans Serif", "WenQuanYi Micro Hei", sans-serif;
  }

  .terms p, .secrecy p {
    padding: 1rem 0 0 0;
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 3rem;
    color: rgb(86, 86, 86);
  }

  .terms h2, .secrecy h2 {
    padding: 1.5rem 0 0 0;
    line-height: 4rem;
    font-size: 2.4rem;
    font-weight: 800;
  }

  .terms li, .secrecy li {
    list-style: disc;
    margin-left: 3.5rem;
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 3rem;
    color: rgb(86, 86, 86);
  }

  .secrecy .secrecy-li {
    list-style: circle;
    margin-left: 6rem;
  }

  .prohibited p:nth-child(n+ 4):not(.notleft) {
    padding-left: 2.5rem;
  }

</style>

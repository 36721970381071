<template>
  <div class="ves">
    <div class="banner">
      <div class="sky">
        <div class="around">
          <div class="ber">
            <div class="download-parent">
              <div id="cav" class="langlist"
                   v-html="store.languages[store.lang]['slogan'] || '无限流量, 高清秒开<br/>无日志, 防跟踪'"></div>
              <div>
                <table>
                  <tbody>

                  <tr v-if="os === 'android'">
                    <td>
                      <div>
                      <a @click="downloadInstall(['download-button-android'], $event)"
                         class="theme-button download-button download-button-android"
                         :href="`/${store.env.app}/apks/${store.env.version}/${store.env.app}-${store.env.version}-release-${store.downloads || store.env.channel}.apk`">
                        <span><img class="thumbnail" src="@/assets/images/Android@2x.png"/></span>
                        <span class="title">{{store.languages[store.lang]['android-download'] || 'Android 下载'}}</span>
                      </a>
                      </div>
                    </td>
                  </tr>

                  <tr v-if="os === 'ios'">
                    <td>
                      <div class=" goto-ios" @click="router.push({name: 'ios'})">
                        <a class="theme-button" href="javascript:void(0)">
                          <span><img src="@/assets/images/Apple@2x.png"/></span>
                          <span class="title">{{store.languages[store.lang]['ios-download']}}</span>
                        </a>
                      </div>
                    </td>
                  </tr>

                  <tr v-if="os === 'macos'">
                    <td>
                      <div class="">
                        <a
                            @click="() => {router.push({name: 'macos'})}"
                            class="theme-button"
                            href="javascript:void(0)">
                          <span><img src="@/assets/images/Apple@2x.png"/></span>
                          <span class="title">{{store.languages[store.lang]['macos-download']}}</span>
                        </a>
                      </div>
                    </td>
                  </tr>

                  <tr v-if="os === 'windows'">
                    <td>
                      <div>
                      <a @click="downloadInstall(['download-button-windows'], $event)"
                         class="theme-button download-button download-button-windows"
                         :href="`/${store.env.app}/exes/${store.env.version}/${store.env.app}-${store.env.version}-release-${store.env.channel}.exe`">
                        <span><img class="thumbnail" src="@/assets/images/win.png"/></span>
                        <span class="title">{{store.languages[store.lang]['windows-download']}}</span>
                      </a>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <div class="other-platform">
                        <span>
                          <span class="title" style="cursor: text;padding-right: .4rem;">{{store.languages[store.lang]['other-platform']}}</span>
                        </span>
                        <span>
                          <a  v-if="os !== 'android'"
                              @click="downloadInstall(['download-button-android'], $event)"
                             class="download-button download-button-android"
                             :href="`/${store.env.app}/apks/${store.env.version}/${store.env.app}-${store.env.version}-release-${store.downloads || store.env.channel}.apk`">
                            <span class="other-platform-icon"><img class="thumbnail" src="@/assets/images/Android@2x.png"/></span>
                          </a>

                          <a  v-if="os !== 'ios'"
                              @click="router.push({name: 'ios'})"
                              class="" href="javascript:void(0)">
                            <span class="other-platform-icon"><img src="@/assets/images/ios.svg"/></span>
                          </a>

                          <a  v-if="os !== 'macos'"
                              @click="() => {router.push({name: 'macos'})}"
                              href="javascript:void(0)">
                            <span class="other-platform-icon"><img style="width: 26px;height: 26px;" src="@/assets/images/mac.svg"/></span>
                          </a>

                          <a   v-if="os !== 'windows'"
                              @click="downloadInstall(['download-button-windows'], $event)"
                             class=" download-button download-button-windows"
                             :href="`/${store.env.app}/exes/${store.env.version}/${store.env.app}-${store.env.version}-release-${store.env.channel}.exe`">
                            <span class="other-platform-icon"><img class="thumbnail" style="height: 22px;" src="@/assets/images/win.png"/></span>
                          </a>

                        </span>
                      </div>
                    </td>
                  </tr>

                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { useStore } from 'vuex'
  import { useRouter } from 'vue-router'
  import utils from "@/utils/utils";
  import {ref} from "vue";

  export default {
    name: 'Banner',
    components: {},
    setup () {
      let useStores = useStore()
      let store = useStores.state

      let router = useRouter()

      function downloadInstall (className, event) {
        let resp = window.install('', className)
        if (!resp) event.preventDefault()
        return resp
      }

      const os = utils.getOSName()

      let from = ref(utils.getQuery('from') || 'index')
      if (window.env && window.env.channel) from.value = window.env.channel
      if (window.location.href.includes('index')) from.value = 'index'

      return {
        router,
        os,
        store,
        downloadInstall,
        utils,
        from
      }
    }
  }
</script>

<style scoped>
  .ves {
    height: 100%;
  }

  .banner {
    background-color: #a1eeff;
    height: 100%;
  }

  .sky {
    background: url('~@/assets/images/sky.png') no-repeat right bottom;
    background-size: auto 85%;
    height: 100%;
  }

  .around {
    background: url('~@/assets/images/around.png') no-repeat right bottom;
    background-size: auto 45%;
    height: 100%;
  }

  .ber {
    background: url('~@/assets/images/goji.png') no-repeat right bottom;
    background-size: auto 83%;
    height: 100%;
  }

  .download-parent {
    padding-top: calc(3.5% + 5.24rem);
    padding-left: 12.5%;
    display: flex;
    flex-direction: column;
  }

  .download-parent a {
    min-width: 17.5rem;
    height: 4rem;
    text-decoration: none;
    justify-content: center;
    cursor: pointer;
    color: #000000;
  }

  .download-parent > div:last-child {
    max-width: 19rem;
  }

  .download-parent .wz18{
    display: flex;
  }
  .download-parent .wz18 img{
    width: auto;
    height: 18px;
  }
  .download-parent span img {
    display: inline;
    height: 24px;
    width: auto;
    align-self: center;
    padding-left: 2rem;
  }

  .download-parent div div {
    cursor: pointer;
    justify-content: center;
  }

  .download-parent div table tr:not(:first-child) td div {
    margin-top: 2rem;
  }

  .download-parent div table tr:last-child td div {
    margin-bottom: 9rem;
  }

  .download-parent div .other-platform {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    -webkit-tap-highlight-color: transparent;
  }
  .download-parent div .other-platform .other-platform-icon {
    display: inline-flex;
  }
  .download-parent div .other-platform .other-platform-icon img{
    padding-left: 1rem;
  }

  #download-android-parent {
    margin-bottom: 2rem;
  }

  #cav {
    margin-bottom: 4rem;
    white-space: nowrap;
    display: inline-block;
    font-size: 3.8em;
    text-align: initial;
    color: black;
    cursor: auto;
  }

  .theme-button {
    display: flex;
    background-color: white;
    border-radius: 2.5rem;
    border: 0.2rem solid black;
    color: black;
    text-align: center;
    box-sizing: border-box;
  }

  .theme-button span {
    align-self: center;
    white-space: nowrap
  }

  .theme-button .title {
    font-size: 1.5rem;
    padding-left: 1rem;
    padding-right: 2rem;
  }
  .theme-button .other-title {
    font-size: 1.3rem;
  }

  @media all and (max-width: 366px) and (min-width: 150px) {

    .sky {
      background-position: center bottom;
      background-size: auto 245px;
    }

    .around {
      background-size: 32%;
    }

    .ber {
      background-size: 100% auto;
    }

    .download-parent {
      padding-top: calc(5.5% + 5.24rem);
      padding-left: 5.5%;
    }


    .download-parent div table tr:last-child td div {
      margin-bottom: 17rem;
    }

    #cav {
      font-size: 2rem;
    }
  }

  @media all and (max-width: 530.999px) and (min-width: 366px) {
    .sky {
      background-size: auto 300px;
    }

    .around {
      background-size: 32%;
    }

    .ber {
      background-size: 100% auto;
    }

    .download-parent {
      padding-top: calc(5.5% + 5.24rem);
      padding-left: 5.5%;
    }


    .download-parent div table tr:last-child td div {
      margin-bottom: 19rem;
    }

    #cav {
      font-size: 2.8rem;
    }
  }

  @media all and (max-width: 800px) and (min-width: 531px) {
    .sky {
      background-size: auto 330px;
    }

    #cav {
      font-size: 3.4rem;
    }
  }

  @media all and (max-width: 1100px) and (min-width: 530px) {
    .sky {
      background-size: 86%;
    }

    .around {
      background-size: 21%;
    }

    .ber {
      background-size: 75%;
    }
  }
  @media all and (min-width: 750px) {
    /*.download-parent a{*/
    /*  min-width: 22rem;*/
    /*  min-height: 4.8rem;*/
    /*}*/
    .download-parent div table tr:not(:first-child) td div {
      margin-top: 2rem;
    }
  }
</style>

import policyLanguages from "../common/policyLanguages"
import termsLanguages from "../common/termsLanguages"
import languages from "../common/languages"
import { createStore } from 'vuex'


let domain = process.env.VUE_APP_HOST_OC
// Create a new store instance.
const store = createStore({
  state () {
    return {
      lang: 'zh',
      termsLanguages,
      policyLanguages,
      footerHeight: 0,
      languages,
      domain,
      headHeight: 0,
    }
  },
  mutations: {
    change (state, currentLang) {
      state.lang = currentLang
    },
    setDomain(state, domain) {
      state.domain = domain
    },
    setHeight (state, typevalue) {
      state[typevalue[0]] = typevalue[1]
    }
  }
})
export default  store

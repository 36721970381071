

let oc = process.env.VUE_APP_HOST_OC
let ocBackup = process.env.VUE_APP_HOST_OC_BACKUP
const domainsOc = [oc, ocBackup]

const config = {
    domains: {
        oc: process.env.VUE_APP_HOST_OC,
        tc: process.env.VUE_APP_HOST_TC,
        cas: process.env.VUE_APP_HOST_CAS,
        tdc: process.env.VUE_APP_HOST_TDC,
    }
}

function resetConfig(data) {
    if (!data) return
    for (let domainsKey in config.domains) {
        config.domains[domainsKey] = data.replace('https://oc', `https://${domainsKey}`)
    }
}

// function verifyDomain() {
//     return new Promise((resolve) => {
//         axios.head(`${oc}/api/website/vue/lpt-title`, {timeout: 2000})
//             .then(() => {
//                 resolve(oc);
//             })
//             .catch(() => {
//                 resolve(ocBackup);
//             });
//     });
// }


function init(){
    let ds = domainsOc[Math.floor(Math.random()*domainsOc.length)];
    resetConfig(ds)
}

init()

export function getDomain(sd) {
    if (!sd) return
    if (!config.domains[sd]) return
    return config.domains[sd]
}


let languages = {
  "en": {
    "title": "GoGoJumpVPN",
    "icon-text": "GoGoJumpVPN",
    "slogan": "Anonymous browsing<br/>HD video streaming<br/>Unlimited usage",
    "android-download": "Android Download",
    "ios-download": "iOS Download",
    "windows-download": "PC Download",
    "macos-download": "macOS Download",
    "other-platform": "Other Platform",
    "recommended": "We Bring You Quality Service",
    "waiter-title-1": "Unlock streaming services, global video platforms can be watched smoothly",
    "waiter-content-1": "Lifting geographical restrictions, you can smoothly watch NETFLIX, HULU, HBO, AbemaTV, Disney and other streaming media content in any part of the world, allowing you to freely access your favorite content.",
    "waiter-title-2": "Smooth use of unavailable applications and services in some areas",
    "waiter-content-2": "GoGoJumpVPN allows you to use Twitter, Instagram, Facebook and other applications freely, and will never drop your connection 7x24 hours, making it easy to interact with friends in different parts of the world.",
    "waiter-title-3": "Anonymous access to apps and websites without logs to protect privacy ",
    "waiter-content-3": "GoGoJumpVPN promises to always adhere to the no-logging policy, to ensure that your access data on all applications and platforms is completely anonymous and unrecorded, and to protect your privacy to a greater extent.",
    "waiter-title-4": "Self-developed patent dedicated line to ensure high speed and low latency",
    "waiter-content-4": "GoGoJumpVPN accelerates all types of server nodes, using self-developed patented acceleration technology and ultra-fast S-IPLC dedicated line to ensure that it can maintain a low-latency and no-stutter-free experience regardless of whether it is on weekdays or during peak hours.",
    "waiter-title-5": "Multi-protocol support to ensure stability without losing the link",
    "waiter-content-5": "Use v2ray, trojan and other protocols to ensure that network nodes are not affected by most events, and high-speed and stable operation can be guaranteed at all times.",
    "waiter-title-6": "Highest security CDC encryption technology",
    "waiter-content-6": "Any access to your connection will be protected. GoGoJumpVPN highest security CDC encryption technology can ensure that any of your data will not be snooped, so you can use it with confidence.",

    "choose-us": "Why Choose Us",
    "servers": "Servers",
    "countries": "Countries",
    "users": "Users",

    "reviews": "User Reviews",
    "download-now": "Download Now",


    "about-us": "About",
    "terms-service": "Terms of Service ",
    "privacy-policy": "Privacy Policy ",
    "help-center": "Help",
    "online-customer": "Inside Website",
    "common-problem": "FAQ",
    "multiPlatformDownload": "Downloads",
    "partners": "Partners",
    "product": "GoGoJumpVPN"
  },
  "zh": {
    "title": "狗急加速",
    "icon-text": "狗急加速",
    "slogan": "无限流量, 高清秒开<br/>无日志, 防跟踪",
    "android-download": "Android 下载",
    "ios-download": "iOS 下载",
    "windows-download": "PC 下载",
    "macos-download": "macOS 下载",
    "other-platform": "其它平台",
    "recommended": "我们为您带来优质的服务",
    "waiter-title-1": "解锁流媒体服务，全球视频平台都可以流畅观看",
    "waiter-content-1": "解除地域限制，在世界任何地区，都能流畅观看 NETFLIX、HULU、HBO、AbemaTV、Disney 等流媒体内容，让您能自由地访问自己喜欢的内容。",
    "waiter-title-2": "流畅使用部分地区内，无法使用的应用和服务",
    "waiter-content-2": "狗急加速可以让您自由使用 Twitter，Instagram 和Facebook 等应用，7x24小时永不掉线，方便和全世界不同地区的朋友进行互动。",
    "waiter-title-3": "无日志、匿名访问应用和网站，保护您的隐私",
    "waiter-content-3": "狗急加速承诺永远坚持无日志记录的政策，确保您在所有应用程序和平台的访问数据，完全匿名且无记录，更大程度的保护您的隐私。",
    "waiter-title-4": "自研专利专线，确保高速低延迟",
    "waiter-content-4": "狗急加速所有类型的服务器节点，都使用了自研的专利加速技术，更有超快 S-IPLC 专线，确保无论是平日还是高峰，都能保持低延迟无卡顿的使用体验。",
    "waiter-title-5": "多协议支持，确保稳定不掉链",
    "waiter-content-5": "使用 v2ray，trojan 等多种协议，保证网络节点不受大部分事件影响，无论何时，都能保证高速稳定的运行。",
    "waiter-title-6": "最高安全 CDC 加密技术",
    "waiter-content-6": "您的任何连接访问，都将受到保护。狗急加速最高安全 CDC 加密技术，可以确保您的任何数据，都不会被窥探到，尽可放心使用。",

    "choose-us": "为什么选择狗急加速",
    "servers": "国际节点",
    "countries": "国家地区",
    "users": "满意用户",

    "reviews": "用户评价",
    "download-now": "立即下载",


    "about-us": "关于我们",
    "terms-service": "服务条款",
    "privacy-policy": "隐私政策",
    "help-center": "帮助中心",
    "online-customer": "在线客服",
    "common-problem": "常见问题",
    "partners": "合作",
    "multiPlatformDownload": "多平台下载",
    "product": "狗急加速"
  },
  "tw": {
    "title": "狗急加速",
    "icon-text": "狗急加速",
    "slogan": "無限流量, 高清秒開<br/>無日志, 防跟蹤",
    "android-download": "Android 下載",
    "ios-download": "iOS 下載",
    "windows-download": "PC 下載",
    "macos-download": "macOS 下载",
    "other-platform": "其他平台",
    "recommended": "我們爲您帶來優質的服務",
    "waiter-title-1": "解鎖流媒體服務，全球視頻平台都可以流暢觀看",
    "waiter-content-1": "解除地域限制，在世界任何地區，都能流暢觀看 NETFLIX、HULU、HBO、AbemaTV、Disney 等流媒體內容，讓您能自由地訪問自己喜歡的內容。",
    "waiter-title-2": "流暢使用部分地區內，無法使用的應用和服務",
    "waiter-content-2": "狗急加速可以讓您自由使用 Twitter，Instagram 和Facebook 等應用，7x24小時永不掉線，方便和全世界不同地區的朋友進行互動。",
    "waiter-title-3": "無日志、匿名訪問應用和網站，保護您的隱私",
    "waiter-content-3": "狗急加速承諾永遠堅持無日志記錄的政策，確保您在所有應用程序和平台的訪問數據，完全匿名且無記錄，更大程度的保護您的隱私。",
    "waiter-title-4": "自研專利專線，確保高速低延遲",
    "waiter-content-4": "狗急加速所有類型的服務器節點，都使用了自研的專利加速技術，更有超快 S-IPLC 專線，確保無論是平日還是高峰，都能保持低延遲無卡頓的使用體驗。",
    "waiter-title-5": "多協議支持，確保穩定不掉鏈",
    "waiter-content-5": "使用 v2ray，trojan 等多種協議，保證網絡節點不受大部分事件影響，無論何時，都能保證高速穩定的運行。",
    "waiter-title-6": "最高安全 CDC 加密技術",
    "waiter-content-6": "您的任何連接訪問，都將受到保護。狗急加速最高安全 CDC 加密技術，可以確保您的任何數據，都不會被窺探到，盡可放心使用。",

    "choose-us": "爲什麽選擇狗急加速",
    "servers": "國際節點",
    "countries": "國家地區",
    "users": "滿意用戶",

    "reviews": "用戶評價",
    "download-now": "立即下載",

    "about-us": "關于我們",
    "terms-service": "服務條款",
    "privacy-policy": "隱私政策",
    "help-center": "幫助中心",
    "online-customer": "在線客服",
    "common-problem": "常見問題",
    "partners": "合作",
    "multiPlatformDownload": "多平台下載",
    "product": "狗急加速"

  }
}

export default languages

const HUAWEI = [
    "ABR-AL00",
    "AGS-L03",
    "AGS-L09",
    "AGS-W09",
    "AGS2-AL00",
    "AGS2-L03",
    "AGS2-L09",
    "AGS2-W09",
    "AGS2-W19",
    "AGS3-AL00",
    "AGS3-L09",
    "AGS3-W00D",
    "AGS3-W00E",
    "AGS3K-W10",
    "AKA-AL10",
    "ALE-TL00",
    "ALP-AL00",
    "ALP-L09",
    "ALP-L29",
    "ALP-TL00",
    "AMN-LX2",
    "AMN-LX3",
    "AMN-LX9",
    "ANA-AL00",
    "ANA-AL000",
    "ANA-AN00",
    "ANA-LX4",
    "ANA-NX9",
    "ANA-TN00",
    "ANE-AL00",
    "ANE-LX1",
    "ANE-LX2",
    "ANE-LX2J",
    "ANE-LX3",
    "ANE-TL00",
    "ANG-AN00",
    "AQM-AL00",
    "AQM-LX1",
    "AQM-TL00",
    "ARE-AL00",
    "ARE-AL10",
    "ARS-AL00",
    "ARS-L22",
    "ARS-TL00",
    "ART-AL00m",
    "ART-AL00x",
    "ART-L28",
    "ART-L29",
    "ART-TL00x",
    "ATH-AL00",
    "ATU-AL10",
    "ATU-L22",
    "ATU-L31",
    "ATU-L42",
    "ATU-LX3",
    "ATU-TL10",
    "BAC-AL00",
    "BAC-L03",
    "BAC-L21",
    "BAC-L22",
    "BAC-TL00",
    "BAH-AL00",
    "BAH-W09",
    "BAH2-AL10",
    "BAH2-L09",
    "BAH2-W09",
    "BAH2-W19",
    "BAH3-AL00",
    "BAH3-AN10",
    "BAH3-L09",
    "BAH3-W09",
    "BAH3-W59",
    "BAL-AL00",
    "BG2-U01",
    "BG2-W09",
    "BGO-DL09",
    "BKL-AL00",
    "BKL-AL20",
    "BLA-A09",
    "BLA-AL00",
    "BLA-AL00-PD",
    "BLA-L09",
    "BLA-L29",
    "BLA-TL00",
    "BLL-L21",
    "BLL-L22",
    "BLL-L23",
    "BLN-AL10",
    "BMH-AN10",
    "BND-AL10",
    "BND-L34",
    "BRQ-AL00",
    "BRQ-AN00",
    "BTV-DL09",
    "BTV-W09",
    "BZA-L00",
    "BZA-W00",
    "BZC-AL00",
    "BZD-AL00",
    "BZD-W00",
    "BZK-L00",
    "BZK-W00",
    "BZT-AL00",
    "BZT-AL10",
    "BZT-W09",
    "BZT3-AL00",
    "BZT3-W09",
    "BZW-AL00",
    "BZW-AL10",
    "CAG-L03",
    "CAG-L22",
    "CAM-L03",
    "CAM-L21",
    "CAM-L23",
    "CAM-L32",
    "CAZ-AL10",
    "CDL-AN50",
    "CDY-AN00",
    "CDY-AN20",
    "CDY-AN90",
    "CDY-NX9A",
    "CDY-NX9B",
    "CDY-TN00",
    "CDY-TN20",
    "CHL-AL60",
    "CHM-TL00H",
    "CLT-AL00",
    "CLT-AL00l",
    "CLT-AL01",
    "CLT-L04",
    "CLT-L09",
    "CLT-L29",
    "CLT-TL00",
    "CLT-TL01",
    "CMR-AL09",
    "CMR-AL19",
    "CMR-W09",
    "CMR-W19",
    "CND-AN00",
    "COL-AL10",
    "COL-TL10",
    "COR-AL00",
    "COR-AL10",
    "CPN-AL00",
    "CPN-AL10",
    "CPN-L09",
    "CPN-W09",
    "CRO-L22",
    "CRO-L23",
    "CRO-U00",
    "CRR-CL00",
    "CUN-AL00",
    "CUN-L22",
    "CUN-TL00",
    "DBY-W09",
    "DIG-AL00",
    "DIG-L21HN",
    "DIG-TL10",
    "DLT-AL10",
    "DRA-AL00",
    "DRA-L21",
    "DRA-LX2",
    "DRA-LX3",
    "DRA-LX5",
    "DRA-LX9",
    "DRA-TL00",
    "DRC-HWMAE",
    "DUB-AL00",
    "DUB-AL00a",
    "DUB-AL20",
    "DUB-LX1",
    "DUB-LX2",
    "DUB-LX3",
    "DUB-TL00",
    "DUB-TL00a",
    "DUK-AL20",
    "DVC-AN00",
    "DVC-AN20",
    "DVC-TN20",
    "EBG-AN00",
    "EBG-AN10",
    "EDI-AL10",
    "EDI-AL100",
    "ELE-AL00",
    "ELE-L04",
    "ELE-L09",
    "ELE-L29",
    "ELE-TL00",
    "ELS-AN00",
    "ELS-AN10",
    "ELS-N04",
    "ELS-N39",
    "ELS-NX9",
    "ELS-TN00",
    "EML-AL00",
    "EML-L09",
    "EML-L29",
    "EML-TL00",
    "EVA-AL00",
    "EVA-AL10",
    "EVA-CL00",
    "EVA-DL00",
    "EVA-L09",
    "EVA-L19",
    "EVA-L29",
    "EVA-TL00",
    "EVA_TL00",
    "EVR-AL00",
    "EVR-AN00",
    "EVR-L29",
    "EVR-TL00",
    "FDR-A01w",
    "FDR-A03L",
    "FIG-AL00",
    "FIG-AL10",
    "FIG-LA1",
    "FIG-LX1",
    "FIG-LX2",
    "FIG-LX3",
    "FIG-TL00",
    "FIG-TL10",
    "FLA-AL00",
    "FLA-AL10",
    "FLA-AL20",
    "FLA-LX2",
    "FLA-LX3",
    "FLA-TL10",
    "FRD-AL00",
    "FRL-AN00a",
    "FRL-L22",
    "FRL-TN00",
    "GEM-702L",
    "GEM-703L",
    "GEM-703LT",
    "GLK-AL00",
    "GLK-LX1U",
    "GLK-TL00",
    "GRA-UL00",
    "GRA-UL10",
    "GRE-AN00",
    "H30-T00",
    "H60-L21",
    "HLK-AL00",
    "HLK-AL10",
    "HMA-AL00",
    "HMA-L09",
    "HMA-L29",
    "HMA-TL00",
    "HRY-AL00",
    "HRY-AL00Ta",
    "HUAWEI CAM-L23",
    "HUAWEI CAZ-AL00",
    "HUAWEI CAZ-AL10",
    "HUAWEI CAZ-TL10",
    "HUAWEI CAZ-TL20",
    "HUAWEI CRR-CL00",
    "HUAWEI CRR-UL00",
    "HUAWEI CRR-UL20",
    "HUAWEI CUN-U29",
    "HUAWEI GRA-CL00",
    "HUAWEI GRA-CL10",
    "HUAWEI GRA-L09",
    "HUAWEI GRA-TL00",
    "HUAWEI GRA-UL00",
    "HUAWEI GRA-UL10",
    "HUAWEI KII-L21",
    "HUAWEI KII-L22",
    "HUAWEI LUA-L22",
    "HUAWEI LUA-U22",
    "HUAWEI M2-801W",
    "HUAWEI M2-803L",
    "HUAWEI M2-A01L",
    "HUAWEI M2-A01W",
    "HUAWEI MLA",
    "HUAWEI MLA-AL00",
    "HUAWEI MLA-AL10",
    "HUAWEI MLA-L11",
    "HUAWEI MLA-TL00",
    "HUAWEI MLA-TL10",
    "HUAWEI MLA-UL00",
    "HUAWEI MT7-CL00",
    "HUAWEI Mate",
    "HUAWEI NEM-AL10",
    "HUAWEI NMO-L31",
    "HUAWEI NXT-AL10",
    "HUAWEI NXT-CL00",
    "HUAWEI NXT-DL00",
    "HUAWEI NXT-L09",
    "HUAWEI NXT-L29",
    "HUAWEI NXT-TL00",
    "HUAWEI P8max",
    "HUAWEI QHG_F4587",
    "HUAWEI RIO-AL00",
    "HUAWEI RIO-CL00",
    "HUAWEI RIO-L01",
    "HUAWEI RIO-L02",
    "HUAWEI RIO-TL00",
    "HUAWEI RIO-UL00",
    "HUAWEI TAG-AL00",
    "HUAWEI TAG-CL00",
    "HUAWEI TAG-L01",
    "HUAWEI TAG-L22",
    "HUAWEI TAG-L32",
    "HUAWEI TAG-TL00",
    "HUAWEI TIT-AL00",
    "HUAWEI TIT-CL00",
    "HUAWEI TIT-CL10",
    "HUAWEI TIT-TL00",
    "HUAWEI VNS-AL00",
    "HUAWEI VNS-DL00",
    "HUAWEI VNS-L22",
    "HUAWEI VNS-L23",
    "HUAWEI VNS-L31",
    "HUAWEI VNS-TL00",
    "HUAWEI eH880",
    "HUAWEI+CAZ-AL10",
    "HUAWEI+MLA-TL00",
    "HW-01K",
    "HW-02L",
    "HWI-AL00",
    "HWI-AL10",
    "HWI-TL00",
    "HWV32",
    "HWV33",
    "HXX-W09",
    "INE-AL00",
    "INE-LX1",
    "INE-LX1r",
    "INE-LX2",
    "INE-LX2r",
    "INE-TL00",
    "JAD-AL00",
    "JAD-AL50",
    "JAD-AL60",
    "JAD-AN00",
    "JAD-LX9",
    "JAT-L29",
    "JAT-L41",
    "JAT-LX3",
    "JDN2-AL00",
    "JDN2-AL50",
    "JDN2-L09",
    "JDN2-W09",
    "JEF-AN00",
    "JEF-AN20",
    "JEF-NX9",
    "JEF-TN00",
    "JEF-TN20",
    "JER-AN10",
    "JER-AN20",
    "JER-TN10",
    "JER-TN20",
    "JKM-AL00",
    "JKM-AL00a",
    "JKM-AL00b",
    "JKM-LX1",
    "JKM-LX2",
    "JKM-LX3",
    "JKM-TL00",
    "JNY-AL10",
    "JNY-LX1",
    "JNY-LX2",
    "JSC-AL50",
    "JSC-AN00",
    "JSN-AL00",
    "JSN-AL00a",
    "KANT-360S",
    "KKG-AN00",
    "KNT-AL10",
    "KOB-L09",
    "KOB-W09",
    "KTD-AN00",
    "KUL-AN00",
    "LDN-AL00",
    "LDN-AL10",
    "LDN-AL20",
    "LDN-L21",
    "LDN-LX2",
    "LDN-LX3",
    "LDN-TL00",
    "LDN-TL10",
    "LDN-TL20",
    "LIO-AL00",
    "LIO-AN00",
    "LIO-AN00P",
    "LIO-AN00m",
    "LIO-L29",
    "LIO-N29",
    "LIO-TL00",
    "LIO-TN00",
    "LON-AL00",
    "LON-AL00-PD",
    "LON-L29",
    "LYA-AL00",
    "LYA-AL00P",
    "LYA-AL10",
    "LYA-L09",
    "LYA-L0C",
    "LYA-L29",
    "LYA-TL00",
    "M10",
    "MAR",
    "MAR-AL00",
    "MAR-LX1A",
    "MAR-LX1B",
    "MAR-LX1M",
    "MAR-LX2",
    "MAR-LX2B",
    "MAR-LX2J",
    "MAR-LX3A",
    "MAR-LX3Am",
    "MAR-LX3Bm",
    "MAR-TL00",
    "MED-AL00",
    "MED-AL20",
    "MED-LX9",
    "MED-TL00",
    "MHA-AL00",
    "MHA-L09",
    "MHA-L29",
    "MHA-TL00",
    "MLA-AL10",
    "MLD-AL00",
    "MLD-AL10",
    "MON-AL19B",
    "MRD-AL00",
    "MRD-LX1",
    "MRD-LX1F",
    "MRD-LX2",
    "MRD-LX3",
    "MRD-TL00",
    "MRR-W29",
    "MRR-W39",
    "MRX-AL09",
    "MRX-AL19",
    "MRX-AN19",
    "MRX-W09",
    "MRX-W19",
    "MRX-W29",
    "MRX-W39",
    "MXW-AN00",
    "MYA-L03",
    "MYA-L11",
    "MYA-L13",
    "MYA-L22",
    "MYA-L23",
    "MYA-U29",
    "Mate 10 Pro",
    "Mate 30",
    "Mate Xs",
    "Mate20 Pro",
    "Mate40Pro 5G",
    "MediaPad10",
    "Mediapad",
    "N95xKP6-HWMAE",
    "NAM-AL00",
    "NAM-LX9",
    "NCE-AL00",
    "NCE-AL10",
    "NCE-TL10",
    "NEM-AL10",
    "NEN-L22",
    "NEO-AL00",
    "NEO-L29",
    "NOH-AL00",
    "NOH-AL10",
    "NOH-AN00",
    "NOH-AN01",
    "NOH-NX9",
    "NOP-AN00",
    "NOP-AN00-PD",
    "NTS-AL00",
    "NXT-AL10",
    "NXT-CL00",
    "NXT-TL00",
    "Nova 8 Pro",
    "OCE-AL50",
    "OCE-AN00",
    "OCE-AN10",
    "OCE-AN50",
    "OXF-AN00",
    "OXF-AN10",
    "OXP-AN00",
    "P20",
    "P20+20PRO",
    "P40",
    "P40 PRO PULS",
    "P6-T00",
    "P8",
    "P9",
    "PAR-AL00",
    "PAR-LX1",
    "PAR-LX1M",
    "PAR-LX9",
    "PAR-TL00",
    "PAR-TL20",
    "PCT-AL10",
    "PE-TL00M",
    "PE-TL10",
    "PIC-AL00",
    "PIC-LX9",
    "PIC-TL00",
    "PLE-703L",
    "PLK-AL10",
    "POT-AL00",
    "POT-AL00/00a",
    "POT-AL00a",
    "POT-AL10",
    "POT-LX1",
    "POT-LX1A",
    "POT-LX3",
    "POT-TL00a",
    "PPA-AL20",
    "PPA-LX1",
    "PPA-LX2",
    "PRA-LX1",
    "PRA-LX2",
    "PRA-LX3",
    "RAI-AN00",
    "RAI-AN10",
    "RIO-TL00",
    "RIO-UL00",
    "RNE-AL00",
    "RNE-AL10",
    "RNE-L01",
    "RNE-L02",
    "RNE-L03",
    "RNE-L21",
    "RNE-L22",
    "RNE-L23",
    "RTE-AL00",
    "RVL-AL09",
    "S8-301U",
    "S8-301W",
    "SCL-AL00",
    "SCM-AL09",
    "SCM-W09",
    "SCMR-AL09",
    "SCMR-W09",
    "SEA-AL00",
    "SEA-AL10",
    "SEA-TL00",
    "SEA-TL10",
    "SHT-AL09",
    "SHT-W09",
    "SLA-AL00",
    "SLA-L02",
    "SLA-L23",
    "SLA-TL10",
    "SNE-AL00",
    "SNE-LX1",
    "SNE-LX2",
    "SNE-LX3",
    "SPN",
    "SPN-AL00",
    "SPN-TL00",
    "STF-AL10",
    "STK-AL00",
    "STK-L21",
    "STK-L22",
    "STK-LX1",
    "STK-LX3",
    "STK-TL00",
    "T1-A23L",
    "TAH-AN00",
    "TAH-AN00m",
    "TAH-N29m",
    "TAS-AL00",
    "TAS-AN00",
    "TAS-L29",
    "TAS-TL00",
    "TEL-AN00",
    "TET-AL00",
    "TET-AN00",
    "TET-AN10",
    "TET-AN50",
    "THAL-560",
    "TNN-AN00",
    "TNNH-AN00",
    "TNY-AL00",
    "TRT-AL00",
    "TRT-AL00A",
    "TRT-L21A",
    "TRT-L53",
    "TRT-LX1",
    "TRT-LX2",
    "TRT-LX3",
    "TRT-TL10",
    "TRT-TL10A",
    "TSA-AL00",
    "TSA-AN00",
    "TYH601M",
    "VCE-AL00",
    "VCE-L22",
    "VCE-TL00",
    "VIE-AL00",
    "VIE-AL10",
    "VIE-L09",
    "VIE-L29",
    "VKY-AL00",
    "VKY-L09",
    "VKY-L29",
    "VKY-TL00",
    "VKY_AL00",
    "VOG-AL00",
    "VOG-AL10",
    "VOG-L04",
    "VOG-L09",
    "VOG-L29",
    "VOG-TL00",
    "VRD-AL09",
    "VRD-AL10",
    "VRD-W09",
    "VRD-W10",
    "VTR-AL00",
    "VTR-L09",
    "VTR-L29",
    "VTR-TL00",
    "WAS-AL00",
    "WAS-LX1",
    "WAS-LX1A",
    "WAS-LX2",
    "WAS-LX2J",
    "WAS-LX3",
    "WAS-TL10",
    "WGR-AN19",
    "WGR-W09",
    "WGR-W19",
    "WKG-AN00",
    "WKG-LX9",
    "WKG-TN00",
    "WLZ-AL10",
    "WLZ-AN00",
    "YAL-AL00",
    "YAL-AL10",
    "YAL-AL50",
    "YAL-L21",
    "YLW-AL00",
    "YOK-AN10",
    "generic_a53",
    "hi6250",
    "huawei p40",
    "iphone6",
    "nova 5 pro",
    "荣耀30s",
    "荣耀Play",
    "ALE-L02",
    "ALE-L21",
    "ALE-L23",
    "ALE-TL00",
    "ALE-UL00",
    "C8816",
    "CHM-TL00",
    "CRR-UL00",
    "EVA-TL00",
    "F100S",
    "FIG-AL00",
    "FRD",
    "FRD-AL00",
    "FRD-AL10",
    "G750-T00",
    "G750-T01",
    "G8142",
    "GN5005",
    "Galaxy S8",
    "H30-T00",
    "H60-L01",
    "H60-L02",
    "H60-L03",
    "H60-L11",
    "H60-L12",
    "HN3-U01",
    "HUAWEI ALE-CL00",
    "HUAWEI C8815",
    "HUAWEI C8818",
    "HUAWEI MT2-L01",
    "HUAWEI MT7-CL00",
    "HUAWEI MT7-J1",
    "HUAWEI MT7-TL00",
    "HUAWEI MT7-TL10",
    "HUAWEI MT7-UL00",
    "HUAWEI P7-L00",
    "HUAWEI P7-L07",
    "HUAWEI P7-L09",
    "HUAWEI SCC-U21",
    "HUAWEI SCL-U31",
    "HUAWEI VOG-AL10",
    "JDN-AL00",
    "JDN-W09",
    "LON-AL00",
    "MAR-LX2J",
    "Mate",
    "Mate 10",
    "Mate 10 Puls",
    "Mate 10 pro",
    "Mate 30 5G",
    "Mate 9",
    "Mate RS",
    "Mediapad",
    "Mediapad-10",
    "Nova 2s",
    "Nova 3",
    "OPPO_A59S",
    "P10 Plus",
    "P30 Pro",
    "P8",
    "P9 Plus",
    "PE-CL00",
    "PE-TL10",
    "PE-TL20",
    "PE-UL00",
    "PLE-703L",
    "T1-821w",
    "T1-823L",
    "T1-A23L",
    "TAG-T100",
    "X1 7.0",
    "mate9",
    "AGM3-AL09HN",
    "AGM3-W09HN",
    "AGR-AL09HN",
    "AGR-W09HN",
    "AGS2-AL00HN",
    "AGS2-W09HN",
    "AGS3-AL09HN",
    "AGS3-W09HN",
    "AKA-AL10",
    "ALA-AN70",
    "ANY-AN00",
    "AQM-AL10",
    "ARE-AL00",
    "ARE-AL10",
    "ARE-L22HN",
    "ARE-TL00",
    "ASK-AL00x",
    "ASK-AL20",
    "ASK-TL00x",
    "ATH-AL00",
    "ATH-CL00",
    "ATH-TL00",
    "ATH-TL00H",
    "ATH-UL00",
    "AUM-AL00",
    "AUM-AL20",
    "AUM-L29",
    "AUM-L33",
    "AUM-TL20",
    "BKK-AL00",
    "BKK-AL10",
    "BKK-LX2",
    "BKK-TL00",
    "BKL-AL00",
    "BKL-AL20",
    "BKL-L04",
    "BKL-L09",
    "BKL-TL10",
    "BLN-AL10",
    "BLN-AL20",
    "BLN-AL30",
    "BLN-AL40",
    "BLN-L21",
    "BLN-L22",
    "BLN-L24",
    "BLN-TL00",
    "BLN-TL10",
    "BMH",
    "BMH-AN10",
    "BMH-AN20",
    "BMH-TN10",
    "BND-AL00",
    "BND-AL10",
    "BND-L21",
    "BND-TL10",
    "BRT-AN09",
    "BRT-W09",
    "CAM-AL00",
    "CAM-L21",
    "CAM-TL00",
    "CAM-TL00H",
    "CAM-UL00",
    "CDY-AN90",
    "CDY-AN95",
    "CDY-TN90",
    "CHL-AL00",
    "CHL-AN00",
    "CLT-AL01",
    "CMA-AN00",
    "COL-AL10",
    "COL-L29",
    "COL-TL10",
    "COR-AL00",
    "COR-AL10",
    "COR-L29",
    "COR-TL10",
    "CUN-AL00",
    "DLI-AL10",
    "DLI-L42",
    "DLI-TL20",
    "DUA-AL00",
    "DUA-L22",
    "DUA-TL00",
    "DUK-AL20",
    "DUK-L09",
    "DUK-TL30",
    "EBG-AN00",
    "EBG-AN10",
    "EBG-TN00",
    "ELZ-AN00",
    "ELZ-AN10",
    "ELZ-AN20",
    "FLS-AN00",
    "FRD-L09",
    "FRD-L14",
    "FRD-L19",
    "GIA-AN00",
    "HDL-AL09",
    "HDL-W09",
    "HDN-L09",
    "HDN-W09",
    "HJC-AN90",
    "HJC-LX9",
    "HLK-AL00",
    "HLK-AL00a",
    "HLK-AL10",
    "HLK-L41",
    "HLK-L42",
    "HNC-AN20",
    "HRY-AL00",
    "HRY-AL00T",
    "HRY-AL00Ta",
    "HRY-AL00a",
    "HRY-LX1",
    "HRY-LX1MEB",
    "HRY-LX1T",
    "HRY-LX2",
    "HRY-TL00",
    "HRY-TL00T",
    "HiTV-M1",
    "JAT-AL00",
    "JAT-L29",
    "JAT-L41",
    "JAT-LX1",
    "JAT-TL00",
    "JDN2-AL00HN",
    "JDN2-AL50HN",
    "JDN2-W09HN",
    "JLH-AN00",
    "JMM-AL00",
    "JMM-AL10",
    "JMM-TL10",
    "JSN-AL00",
    "JSN-AL00a",
    "JSN-L21",
    "JSN-L22",
    "JSN-L23",
    "JSN-L42",
    "JSN-TL00",
    "KIW-AL10",
    "KIW-CL00",
    "KIW-L22",
    "KIW-TL00",
    "KIW-TL00H",
    "KIW-UL00",
    "KJR-W09",
    "KKG-AN00",
    "KKG-AN70",
    "KNT-AL10",
    "KNT-AL20",
    "KNT-TL10",
    "KNT-UL10",
    "KOB2-W09HN",
    "KOZ-AL00",
    "KOZ-AL40",
    "KRJ-AN00",
    "KRJ-W09",
    "KRJ2-W09",
    "KSA-AL00",
    "KSA-AL10",
    "KSA-LX1",
    "KSA-LX2",
    "KSA-LX9",
    "LLD-AL00",
    "LLD-AL10",
    "LLD-AL20",
    "LLD-AL30",
    "LLD-L21",
    "LLD-L31",
    "LLD-TL10",
    "LND-AL30",
    "LND-AL40",
    "LND-L29",
    "LND-TL40",
    "LRA-AL00",
    "LSA-AN00",
    "MAR-LX1H",
    "MGI-AN00",
    "MOA-AL00",
    "MOA-AL20",
    "MOA-L29",
    "MOA-LX9N",
    "MOA-TL00",
    "MXW-AN00",
    "MXW-TN00",
    "MYA-AL10",
    "MYA-TL10",
    "NEM-AL10",
    "NEM-L22",
    "NEM-TL00",
    "NEM-TL00H",
    "NEM-UL10",
    "NEW-AN90",
    "NOH-AN00",
    "NTH-AN00",
    "NTH-NX9",
    "NTN-AN20",
    "NTN-L22",
    "NTN-LX3",
    "NTS-AL00",
    "NZA-AL00",
    "OPPO R11 Plus",
    "OSCA-550A",
    "OXF",
    "OXF-AN00",
    "OXF-AN10",
    "OXP-AN00",
    "PCRM00",
    "PCT-AL10",
    "PCT-L29",
    "PCT-TL10",
    "PLK-AL10",
    "PLK-CL00",
    "PLK-L01",
    "PLK-TL00",
    "PLK-TL01H",
    "PLK-UL00",
    "PRA-AL00",
    "PRA-AL00X",
    "PRA-TL10",
    "RNA-AN00",
    "RNA-TN00",
    "RNE-L22",
    "RVL",
    "RVL-AL09",
    "STF-AL00",
    "STF-AL10",
    "STF-L09",
    "STF-TL10",
    "STK-LX1",
    "STK-LX3",
    "TEL-AN00",
    "TEL-AN00a",
    "TEL-AN10",
    "TEL-TN00",
    "TFY-AN00",
    "TNA-AN00",
    "TNNH-AN00",
    "TNY-AL00",
    "TNY-TL00",
    "VNE-N41",
    "YAL",
    "YAL-AL00",
    "YAL-AL10",
    "YAL-AL50",
    "YAL-L21",
    "YAL-L41",
    "YAL-TL00",
    "YOK-AN10",
    "honor 10",
    "AGR-W09HN",
    "AGS-W09",
    "BKK-AL00",
    "BND-AL00",
    "CDY-AN90",
    "CHM-TL00",
    "COR-AL00",
    "CUN-AL00",
    "DLI-AL10",
    "DUK-AL20",
    "EBG-AN00",
    "FRD-AL00",
    "FRD-AL10",
    "FRD-DL00",
    "H60-L03",
    "HDN-W09",
    "HLK-AL00",
    "HRY-AL00Ta",
    "HRY-AL00a",
    "JDN2-W09HN",
    "KIW-AL00",
    "KNT-AL10",
    "KOB-L09",
    "KRJ-AN00",
    "LLD-AL00",
    "LLD-AL20",
    "MYA-AL10",
    "PLK-AL10",
    "RVL-AL09",
    "S8-701w",
    "STF-AL00",
    "T1-701ua",
    "T1-821w",
    "TNNH-AN00",
    "YAL-AL00",
    "YAL-AL50",
    "bmh-an20",
    "ebg-an00",
    "ebg-an10",
    "hlk-al00",
    "honor8 lite",
    "jsn-al00",
    "nth-an00",
    "pct-al10",
    "tel-an00a",
    "tel-an10",
    "yal-al00",
    "yal-al10",
    "荣耀青春版8",
]

const XIAOMI = [
    " MI 10 Pro",
    "10X",
    "11",
    "11 Pro",
    "2013023",
    "2014216",
    "2014719",
    "2014811",
    "2014812",
    "2014813",
    "2015716",
    "2016060",
    "2016080 ",
    "2016089",
    "21051182C",
    "21051182G",
    "2106118C",
    "2106118c",
    "2107113SG",
    "2107119DC",
    "21081111RG",
    "2109119BC",
    "2109119DG",
    "2112123AC",
    "2201122C",
    "2201123C",
    "8 青春版",
    "8R",
    "9 Pro 5G",
    "9 Pro5G",
    "Aidan's ROM",
    "Archytas",
    "BASIC",
    "CHE-TL00H",
    "DLT-A0",
    "F3B",
    "G7",
    "G8342",
    "GM1917",
    "Google Pixel 4XL",
    "H60-L01",
    "HLK-AL00",
    "HM 2A",
    "HM NOTE 1LTE",
    "HM NOTE 1LTE TD",
    "HM NOTE 1S",
    "HM NOTE LTE",
    "HTC T328t",
    "HTC X920e",
    "J1SC",
    "LEX820",
    "LG V30",
    "LG-US998",
    "LM-V450",
    "LX04",
    "LYA-AL10",
    "Lenovo L78051",
    "M11",
    "M1804D2SE",
    "M1806D9PE",
    "M1806D9W",
    "M1808D2TE",
    "M1904F3BC",
    "M1904F3BT",
    "M1906F9SC",
    "M199",
    "M2",
    "M2002J9E",
    "M2002J9G",
    "M2002J9S",
    "M2003J15SC",
    "M2004J19C",
    "M2004J7AC",
    "M2004J7BC",
    "M2006C3LC",
    "M2006J10C",
    "M2007J17G",
    "M2007J17I",
    "M2007J1SC",
    "M2007J22C",
    "M2007J3SG",
    "M2007J3SY",
    "M2011J18C",
    "M2011K2C",
    "M2011K2G",
    "M2012K11AC",
    "M2012K11G",
    "M2101K9AG",
    "M2101K9C",
    "M2101K9C (renoir)",
    "M2101K9G",
    "M2102J2SC",
    "M2102K1AC",
    "M2102K1C",
    "M2102K1G",
    "M2105K81AC",
    "M2105K81C",
    "M2pro",
    "M355",
    "MDE2",
    "MDE40",
    "MI 10",
    "MI 10 Pro",
    "MI 11",
    "MI 2",
    "MI 2C",
    "MI 2S",
    "MI 2SC",
    "MI 3",
    "MI 3/4",
    "MI 3C",
    "MI 3W",
    "MI 4C",
    "MI 4LTE",
    "MI 4S",
    "MI 4W",
    "MI 5",
    "MI 5C",
    "MI 5X",
    "MI 5s",
    "MI 5s Plus",
    "MI 5s Plus (natrium)",
    "MI 6",
    "MI 6 Plus",
    "MI 6X",
    "MI 6X MIKU",
    "MI 6X [Traveler Dream906 Hacker]",
    "MI 7",
    "MI 8",
    "MI 8 (dipper) (MI 8 (dipper))",
    "MI 8 Explorer Edition",
    "MI 8 Lite",
    "MI 8 Pro",
    "MI 8 SE",
    "MI 8 UD",
    "MI 9",
    "MI 9 ROY",
    "MI 9 SE",
    "MI 9 Transparent Edition",
    "MI 9 pro",
    "MI 9T Pro",
    "MI CC 9",
    "MI CC 9E",
    "MI CC 9e",
    "MI CC9 Pro",
    "MI CC9 Pro Premium Edition",
    "MI MAX",
    "MI MAX 2",
    "MI MAX 3",
    "MI MIX",
    "MI MIX 2",
    "MI MIX2S Emerald Edition",
    "MI NOTE 2",
    "MI NOTE 3",
    "MI NOTE LTE",
    "MI NOTE PRO",
    "MI NOTE Pro",
    "MI Note 2",
    "MI Note 3",
    "MI PAD",
    "MI PAD 2",
    "MI PAD 3",
    "MI PAD 4",
    "MI PAD 4 PLUS",
    "MI PAD 5",
    "MI PLAY",
    "MI POCO X3",
    "MI POCO X3 Pro",
    "MI Pad 2",
    "MI-4C",
    "MI-ONE Plus",
    "MI9",
    "MI9 SE BROWN EDITION",
    "MIBOX4",
    "MIX",
    "MIX 2",
    "MIX 2S",
    "MIX 2S ART",
    "MIX 2S Emerald Edition",
    "MIX 3",
    "MIX 3 The Palace Museum Edition",
    "MIX Alpha",
    "MIX2",
    "MIX3",
    "MX4",
    "MX5",
    "Mi 10",
    "Mi 10 Lite Zoom",
    "Mi 10 Pro",
    "Mi 10 Ultra",
    "Mi 10S",
    "Mi 11",
    "Mi 4i",
    "Mi 5",
    "Mi 5X",
    "Mi 5s",
    "Mi 5s Plus",
    "Mi 9",
    "Mi 9 Lite",
    "Mi 9 SE",
    "Mi 9T",
    "Mi 9T Pro",
    "Mi A1",
    "Mi A2",
    "Mi A2 Lite",
    "Mi A3",
    "Mi MIX 2",
    "Mi MIX 2S",
    "Mi MIX 3",
    "Mi MIX 3 5G",
    "Mi Max 3",
    "Mi Max Prime",
    "Mi Note 10",
    "Mi Note 10 Lite",
    "Mi Note 10 Pro",
    "Mi Note 2",
    "Mi Note 3",
    "Mi Watch",
    "Mi ，gy",
    "Mi-4c",
    "Mi9 Pro 5G",
    "MiBOX3",
    "MiBOX3S",
    "MiBOX3_PRO",
    "MiBOX4",
    "MiBOX4C",
    "MiProjA1",
    "MiProjLED1",
    "MiTV-ANSP0",
    "MiTV-ANTL0",
    "MiTV-ASTP0",
    "MiTV-AZFP0",
    "MiTV-MFTP0",
    "MiTV-MTEQ0",
    "MiTV-MTTP0",
    "MiTV-NSOP0",
    "MiTV3",
    "MiTV3S",
    "MiTV4",
    "MiTV4-ANSM0",
    "MiTV4-MTSM0",
    "MiTV4A",
    "MiTV4C",
    "MiTV4X",
    "Mix",
    "Mix 2",
    "Mix 2S",
    "Mix2",
    "NOH-AN00",
    "OE106",
    "ONEPLUS A5010",
    "ONEPLUS A6010",
    "OPPO R11",
    "POCO F1",
    "POCOPHONE F1",
    "Pixel 2 XL",
    "QK1707",
    "QK1801",
    "Redmi 3",
    "Redmi 37",
    "Redmi 3S",
    "Redmi 3X",
    "Redmi 4",
    "Redmi 4 Prime",
    "Redmi 4A",
    "Redmi 4X",
    "Redmi 5",
    "Redmi 5 Plus",
    "Redmi 5A",
    "Redmi 6",
    "Redmi 6 Pro",
    "Redmi 6A",
    "Redmi 7",
    "Redmi 7A",
    "Redmi 8",
    "Redmi 8A",
    "Redmi Go",
    "Redmi K20",
    "Redmi K20 Pro",
    "Redmi K20 Pro (raphael)",
    "Redmi K20 Pro Premium Edition",
    "Redmi K30",
    "Redmi K30 5G",
    "Redmi K30 5G (picasso)",
    "Redmi K30 Pro",
    "Redmi K30 Pro Zoom Edition",
    "Redmi K30 Ultra",
    "Redmi K30S Ultra",
    "Redmi K30i 5G",
    "Redmi K40 Pro",
    "Redmi Note 2",
    "Redmi Note 3",
    "Redmi Note 4",
    "Redmi Note 4X",
    "Redmi Note 4x",
    "Redmi Note 5",
    "Redmi Note 5 Pro",
    "Redmi Note 5A",
    "Redmi Note 5A Prime",
    "Redmi Note 6 Pro",
    "Redmi Note 7",
    "Redmi Note 7 Pro",
    "Redmi Note 7S",
    "Redmi Note 8",
    "Redmi Note 8 Pro",
    "Redmi Note 8T",
    "Redmi Pro",
    "Redmi S2",
    "Redmi Y1",
    "Redmi Y1 Lite",
    "Redmi Y2",
    "Redmi Y3",
    "Redmi_Note_7",
    "Redmi_Note_8",
    "SM-G9860",
    "Unsupported MI Cancro",
    "XGIMI TV",
    "XIG01",
    "Xiaomi  12 Ultra",
    "Xiaomi 233",
    "Xiaomi 6",
    "Xiaomi Poco f2Pro",
    "Y70-C",
    "ZTE A2020G Pro",
    "hm 1s",
    "hm note 1lte",
    "hm note 1s",
    "huawei Honor V9",
    "iРhone 12 Рro Мax（5G）",
    "mi 10",
    "mi 4lte",
    "mi 5",
    "mi 6",
    "mi 8",
    "mi 9",
    "mi max",
    "mi note lte",
    "mi-4c",
    "mix",
    "redmi 3",
    "redmi 4",
    "redmi k20 pro",
    "redmi note 2",
    "redmi note 4",
    "vmos",
    "wt88047",
    "xiaomi 5s",
    "xiaomi 6",
    "xiaomi 8",
    "xiaomi 9T Pro",
    "xiaomi mix",
    "xiaomiCC9",
    "К20 Рro 尊享版",
    "小米 10 Pro (5G)",
    "小米11 pro",
    "小米8",
    "红米手机4G版(2014501)",
    "小米","小米（MI）","xiaomi","XIAOMI","XGIMI","XIAO MI","XiaoMI","XiaoMi","Xiaomi","M11","M199","M2","M2pro","MI","MI PAD 5"
]

export {HUAWEI, XIAOMI}